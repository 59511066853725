import React from 'react';
import NavBar from './NavBar';
export default function Header() {
  return (
    <>
      <header>
        <NavBar></NavBar>
      </header>
    </>
  );
}
