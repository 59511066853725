export const SEND_CONTACT_US_MAIL_LOADING =
  "SEND_CONTACT_US_MAIL_LOADING";
export const SEND_CONTACT_US_MAIL_SUCCESS =
  "SEND_CONTACT_US_MAIL_SUCCESS";
export const SEND_CONTACT_US_MAIL_ERROR =
  "SEND_CONTACT_US_MAIL_ERROR";
export const SEND_CONTACT_US_MAIL_RESET =
  "SEND_CONTACT_US_MAIL_RESET";

export const SEND_APPLICATION_LOADING = "SEND_APPLICATION_LOADING";
export const SEND_APPLICATION_SUCCESS = "SEND_APPLICATION_SUCCESS";
export const SEND_APPLICATION_ERROR = "SEND_APPLICATION_ERROR";
export const SEND_APPLICATION_RESET = "SEND_APPLICATION_RESET";
