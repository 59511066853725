import React from 'react';
import Typography from '../components/Typography';
import Section from '../components/Section';
import { Row, Col } from 'react-bootstrap';

import { ArrowDown } from 'react-bootstrap-icons';

const ImportantLinks = () => {
  return (
    <Section style={{ minHeight: '100vh' }}>
      <Typography variant='h2'>Important Links</Typography>
      <Row>
        <Col>
          {' '}
          <a
            className='typography--color-primary'
            href='/files/AICTE_Approval_Copies.pdf'
            download
          >
            <ArrowDown
              size='15'
              style={{ marginRight: '10px', marginTop: '5px' }}
            />
            Download AICTE Approval Copies
          </a>
        </Col>
      </Row>
    </Section>
  );
};

export default ImportantLinks;
