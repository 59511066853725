import React, { useContext, useEffect, useState } from "react";
import Stepper from "react-stepper-horizontal/lib/Stepper";
import Button from "../components/Button";
import ItemForm from "../components/forms/ItemForm";
import Row from "../components/Row";
import Section from "../components/Section";
import Typography from "../components/Typography";
import Calendar from "react-calendar";
import { Calendar3 } from "react-bootstrap-icons";
import "react-calendar/dist/Calendar.css";
import { formDataContext } from "../context/FormDataProvider";
import "filepond/dist/filepond.min.css";
import CheckBox from "../components/forms/CheckBox";
const AcademicDetailsScreen = ({ next, previous }) => {
  const { formData, setFormData } = useContext(formDataContext);
  const [windowWidth, setWindowWidth] = useState();

  const buttonDisabled =
    formData.examCodeNo === "HSC Vocational"
      ? !(
          formData.primaryLanguage &&
          formData.primaryMonthandYearOfPassing &&
          formData.english &&
          formData.englishMonthandYearOfPassing &&
          formData.mathematics &&
          formData.mathematicsMonthandYearOfPassing &&
          formData.theory &&
          formData.theoryMonthandYearOfPassing &&
          formData.practical1 &&
          formData.practical1MonthandYearOfPassing &&
          formData.practical2 &&
          formData.practical2MonthandYearOfPassing &&
          formData.ninthMonthandYearOfPassing &&
          formData.ninthNameAndAddressOftheSchool &&
          formData.tenthMonthandYearOfPassing &&
          formData.tenthNameAndAddressOftheSchool &&
          formData.vocationalName
        )
      : !(
          formData.primaryLanguage &&
          formData.primaryMonthandYearOfPassing &&
          formData.english &&
          formData.englishMonthandYearOfPassing &&
          formData.mathematics &&
          formData.mathematicsMonthandYearOfPassing &&
          formData.science &&
          formData.scienceMonthandYearOfPassing &&
          formData.socialScience &&
          formData.socialScienceMonthandYearOfPassing &&
          (formData.electiveAvailable
            ? formData.elective &&
              formData.electiveMonthandYearOfPassing
            : true) &&
          formData.ninthMonthandYearOfPassing &&
          formData.ninthNameAndAddressOftheSchool &&
          formData.tenthMonthandYearOfPassing &&
          formData.tenthNameAndAddressOftheSchool
        );
  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };
  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () =>
      window.removeEventListener("resize", updateDimensions);
  }, []);

  const steps =
    windowWidth > 600
      ? [
          { title: "Personal Details" },
          { title: "Address Details" },
          { title: "School Details" },
          { title: "Academic Details" },
          { title: "Upload Files" },
          { title: "Confirmation" },
        ]
      : [
          { title: "" },
          { title: "" },
          { title: "" },
          { title: "" },
          { title: "" },
          { title: "" },
        ];
  return (
    <Section>
      <div style={{ marginBottom: "50px" }}>
        <Stepper
          steps={steps}
          activeStep={3}
          activeColor='#5bb462'
          completeColor='#5bb462'
          completeTitleColor='#5bb462'
          activeTitleColor='#5bb462'
          titleFontSize={14}
        />
      </div>
      <Typography variant='h4'>Academic Details</Typography>
      <div style={{ marginBottom: "2rem" }}>
        <Row
          v_align='center'
          h_align='space-between'
          style={{ marginBottom: "2rem" }}
        >
          <Typography variant='h6' style={{ flexGrow: "5" }}>
            {formData.formType === "First_Year"
              ? "Marks obtained in SSLC / Equivalent Examination"
              : "Marks obtained in HSC / Equivalent Examination"}
          </Typography>
          {formData.examCodeNo !== "HSC Vocational" ? (
            <CheckBox
              label='Elective Subject Available'
              name='electiveAvailable'
              value={formData.electiveAvailable}
              checked={formData.electiveAvailable}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  electiveAvailable: e.target.checked,
                  elective: formData.elective && "0",
                  total:
                    Number(formData.primaryLanguage) +
                    Number(formData.english) +
                    Number(formData.mathematics) +
                    Number(formData.science) +
                    Number(formData.socialScience),
                })
              }
            />
          ) : (
            <ItemForm
              label='Name of the Vocational Course'
              name='vocationalName'
              value={formData.vocationalName}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  vocationalName: e.target.value,
                });
              }}
              required
            />
          )}
        </Row>
        {formData.examCodeNo === "HSC Vocational" ? (
          <div style={{ overflowX: "auto" }}>
            <table>
              <thead>
                <tr>
                  <th rowSpan='2'>SI.No</th>
                  <th rowSpan='2'>Subject</th>
                  <th colSpan='2'>Marks</th>
                  <th rowSpan='2'>Month and Year of Passing</th>
                </tr>
                <tr>
                  <th>Scored</th>
                  <th>Maximum</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>(i)</td>
                  <td>Tamil or any other language</td>
                  <td>
                    <ItemForm
                      type='number'
                      max={formData.maximumMark}
                      value={formData.primaryLanguage}
                      name='primaryLanguage'
                      onChange={(e) =>
                        e.target.value <= formData.maximumMark &&
                        setFormData({
                          ...formData,
                          primaryLanguage: e.target.value,
                        })
                      }
                      onBlur={(e) => {
                        setFormData({
                          ...formData,
                          total:
                            Number(formData.primaryLanguage) +
                            Number(formData.english) +
                            Number(formData.mathematics) +
                            Number(formData.science) +
                            Number(formData.socialScience) +
                            Number(formData.elective),
                        });
                      }}
                    />
                  </td>
                  <td>{formData.maximumMark}</td>
                  <td>
                    <div className='calendar-input'>
                      <Row>
                        <ItemForm
                          value={
                            formData.primaryMonthandYearOfPassing
                          }
                          disabled
                          name='primaryMonthandYearOfPassing'
                        />
                        <Calendar3
                          className='calendar-input__icon'
                          size='20'
                          onClick={(e) =>
                            setFormData({
                              ...formData,
                              primaryMonthandYearOfPassingCalendar:
                                !formData.primaryMonthandYearOfPassingCalendar,
                            })
                          }
                        />
                      </Row>
                      {formData.primaryMonthandYearOfPassingCalendar && (
                        <Calendar
                          view={"year"}
                          onClickMonth={(e) => {
                            setFormData({
                              ...formData,
                              primaryMonthandYearOfPassing:
                                e.toLocaleString("default", {
                                  month: "long",
                                  year: "numeric",
                                }),
                              primaryMonthandYearOfPassingCalendar: false,
                            });
                          }}
                        />
                      )}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>(ii)</td>
                  <td>English</td>
                  <td>
                    <ItemForm
                      type='number'
                      max={formData.maximumMark}
                      value={formData.english}
                      name='english'
                      onChange={(e) =>
                        e.target.value <= formData.maximumMark &&
                        setFormData({
                          ...formData,
                          english: e.target.value,
                        })
                      }
                      onBlur={(e) => {
                        setFormData({
                          ...formData,
                          total:
                            Number(formData.primaryLanguage) +
                            Number(formData.english) +
                            Number(formData.mathematics) +
                            Number(formData.science) +
                            Number(formData.socialScience) +
                            Number(formData.elective),
                        });
                      }}
                    />
                  </td>
                  <td>{formData.maximumMark}</td>
                  <td>
                    <div className='calendar-input'>
                      <Row>
                        <ItemForm
                          value={
                            formData.englishMonthandYearOfPassing
                          }
                          disabled
                          name='englishMonthandYearOfPassing'
                        />
                        <Calendar3
                          className='calendar-input__icon'
                          size='20'
                          onClick={(e) =>
                            setFormData({
                              ...formData,
                              englishMonthandYearOfPassingCalendar:
                                !formData.englishMonthandYearOfPassingCalendar,
                            })
                          }
                        />
                      </Row>
                      {formData.englishMonthandYearOfPassingCalendar && (
                        <Calendar
                          view={"year"}
                          onClickMonth={(e) => {
                            setFormData({
                              ...formData,
                              englishMonthandYearOfPassing:
                                e.toLocaleString("default", {
                                  month: "long",
                                  year: "numeric",
                                }),
                              englishMonthandYearOfPassingCalendar: false,
                            });
                          }}
                        />
                      )}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>(iii)</td>
                  <td>Mathematics (Or) Physics (Or) Chemistry</td>
                  <td>
                    <ItemForm
                      type='number'
                      max={formData.maximumMark}
                      value={formData.mathematics}
                      name='mathematics'
                      onChange={(e) =>
                        e.target.value <= formData.maximumMark &&
                        setFormData({
                          ...formData,
                          mathematics: e.target.value,
                        })
                      }
                      onBlur={(e) => {
                        setFormData({
                          ...formData,
                          total:
                            Number(formData.primaryLanguage) +
                            Number(formData.english) +
                            Number(formData.mathematics) +
                            Number(formData.science) +
                            Number(formData.socialScience) +
                            Number(formData.elective),
                        });
                      }}
                    />
                  </td>
                  <td>{formData.maximumMark}</td>
                  <td>
                    <div className='calendar-input'>
                      <Row>
                        <ItemForm
                          value={
                            formData.mathematicsMonthandYearOfPassing
                          }
                          disabled
                          name='mathematicsMonthandYearOfPassing'
                        />
                        <Calendar3
                          className='calendar-input__icon'
                          size='20'
                          onClick={(e) =>
                            setFormData({
                              ...formData,
                              mathematicsMonthandYearOfPassingCalendar:
                                !formData.mathematicsMonthandYearOfPassingCalendar,
                            })
                          }
                        />
                      </Row>
                      {formData.mathematicsMonthandYearOfPassingCalendar && (
                        <Calendar
                          view={"year"}
                          onClickMonth={(e) => {
                            setFormData({
                              ...formData,
                              mathematicsMonthandYearOfPassing:
                                e.toLocaleString("default", {
                                  month: "long",
                                  year: "numeric",
                                }),
                              mathematicsMonthandYearOfPassingCalendar: false,
                            });
                          }}
                        />
                      )}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>(iv)</td>
                  <td>Theory</td>
                  <td>
                    <ItemForm
                      type='number'
                      max={formData.maximumMark}
                      value={formData.theory}
                      name='theory'
                      onChange={(e) =>
                        e.target.value <= formData.maximumMark &&
                        setFormData({
                          ...formData,
                          theory: e.target.value,
                        })
                      }
                      onBlur={(e) => {
                        setFormData({
                          ...formData,
                          total:
                            Number(formData.primaryLanguage) +
                            Number(formData.english) +
                            Number(formData.mathematics) +
                            Number(formData.theory) +
                            Number(formData.practical1) +
                            Number(formData.practical2),
                        });
                      }}
                    />
                  </td>
                  <td>{formData.maximumMark}</td>
                  <td>
                    <div className='calendar-input'>
                      <Row>
                        <ItemForm
                          value={formData.theoryMonthandYearOfPassing}
                          disabled
                          name='theoryMonthandYearOfPassing'
                        />
                        <Calendar3
                          className='calendar-input__icon'
                          size='20'
                          onClick={(e) =>
                            setFormData({
                              ...formData,
                              theoryMonthandYearOfPassingCalendar:
                                !formData.theoryMonthandYearOfPassingCalendar,
                            })
                          }
                        />
                      </Row>
                      {formData.theoryMonthandYearOfPassingCalendar && (
                        <Calendar
                          view={"year"}
                          onClickMonth={(e) => {
                            setFormData({
                              ...formData,
                              theoryMonthandYearOfPassing:
                                e.toLocaleString("default", {
                                  month: "long",
                                  year: "numeric",
                                }),
                              theoryMonthandYearOfPassingCalendar: false,
                            });
                          }}
                        />
                      )}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>(v)</td>
                  <td>Practical 1</td>
                  <td>
                    <ItemForm
                      type='number'
                      max={formData.maximumMark}
                      value={formData.practical1}
                      name='practical1'
                      onChange={(e) =>
                        e.target.value <= formData.maximumMark &&
                        setFormData({
                          ...formData,
                          practical1: e.target.value,
                        })
                      }
                      onBlur={(e) => {
                        setFormData({
                          ...formData,
                          total:
                            Number(formData.primaryLanguage) +
                            Number(formData.english) +
                            Number(formData.mathematics) +
                            Number(formData.theory) +
                            Number(formData.practical1) +
                            Number(formData.practical1),
                        });
                      }}
                    />
                  </td>
                  <td>{formData.maximumMark}</td>
                  <td>
                    <div className='calendar-input'>
                      <Row>
                        <ItemForm
                          value={
                            formData.practical1MonthandYearOfPassing
                          }
                          disabled
                          name='practical1MonthandYearOfPassing'
                        />
                        <Calendar3
                          className='calendar-input__icon'
                          size='20'
                          onClick={(e) =>
                            setFormData({
                              ...formData,
                              practical1MonthandYearOfPassingCalendar:
                                !formData.practical1MonthandYearOfPassingCalendar,
                            })
                          }
                        />
                      </Row>
                      {formData.practical1MonthandYearOfPassingCalendar && (
                        <Calendar
                          view={"year"}
                          onClickMonth={(e) => {
                            setFormData({
                              ...formData,
                              practical1MonthandYearOfPassing:
                                e.toLocaleString("default", {
                                  month: "long",
                                  year: "numeric",
                                }),
                              practical1MonthandYearOfPassingCalendar: false,
                            });
                          }}
                        />
                      )}
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>(vi)</td>
                  <td>Practical 2</td>
                  <td>
                    <ItemForm
                      type='number'
                      max={formData.maximumMark}
                      value={formData.practical2}
                      name='practical2'
                      onChange={(e) =>
                        e.target.value <= formData.maximumMark &&
                        setFormData({
                          ...formData,
                          practical2: e.target.value,
                        })
                      }
                      onBlur={(e) => {
                        setFormData({
                          ...formData,
                          total:
                            Number(formData.primaryLanguage) +
                            Number(formData.english) +
                            Number(formData.mathematics) +
                            Number(formData.science) +
                            Number(formData.socialScience) +
                            Number(formData.practical2),
                        });
                      }}
                    />
                  </td>
                  <td>{formData.maximumMark}</td>
                  <td>
                    <div className='calendar-input'>
                      <Row>
                        <ItemForm
                          value={
                            formData.practical2MonthandYearOfPassing
                          }
                          disabled
                          name='practical2MonthandYearOfPassing'
                        />
                        <Calendar3
                          className='calendar-input__icon'
                          size='20'
                          onClick={(e) =>
                            setFormData({
                              ...formData,
                              practical2MonthandYearOfPassingCalendar:
                                !formData.practical2MonthandYearOfPassingCalendar,
                            })
                          }
                        />
                      </Row>
                      {formData.practical2MonthandYearOfPassingCalendar && (
                        <Calendar
                          view={"year"}
                          onClickMonth={(e) => {
                            setFormData({
                              ...formData,
                              practical2MonthandYearOfPassing:
                                e.toLocaleString("default", {
                                  month: "long",
                                  year: "numeric",
                                }),
                              practical2MonthandYearOfPassingCalendar: false,
                            });
                          }}
                        />
                      )}
                    </div>
                  </td>
                </tr>

                <tr>
                  <td></td>
                  <td>TOTAL</td>
                  <td>{formData.total}</td>
                  <td>
                    {formData.electiveAvailable ||
                    formData.examCodeNo === "HSC Vocational"
                      ? "600"
                      : "500"}
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          <div style={{ overflowX: "auto" }}>
            <table>
              <thead>
                <tr>
                  <th rowSpan='2'>SI.No</th>
                  <th rowSpan='2'>Subject</th>
                  <th colSpan='2'>Marks</th>
                  <th rowSpan='2'>Month and Year of Passing</th>
                </tr>
                <tr>
                  <th>Scored</th>
                  <th>Maximum</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>(i)</td>
                  <td>Tamil or any other language</td>
                  <td>
                    <ItemForm
                      type='number'
                      max={formData.maximumMark}
                      value={formData.primaryLanguage}
                      name='primaryLanguage'
                      onChange={(e) =>
                        e.target.value <= formData.maximumMark &&
                        setFormData({
                          ...formData,
                          primaryLanguage: e.target.value,
                        })
                      }
                      onBlur={(e) => {
                        setFormData({
                          ...formData,
                          total:
                            Number(formData.primaryLanguage) +
                            Number(formData.english) +
                            Number(formData.mathematics) +
                            Number(formData.science) +
                            Number(formData.socialScience) +
                            Number(formData.elective),
                        });
                      }}
                    />
                  </td>
                  <td>{formData.maximumMark}</td>
                  <td>
                    <div className='calendar-input'>
                      <Row>
                        <ItemForm
                          value={
                            formData.primaryMonthandYearOfPassing
                          }
                          disabled
                          name='primaryMonthandYearOfPassing'
                        />
                        <Calendar3
                          className='calendar-input__icon'
                          size='20'
                          onClick={(e) =>
                            setFormData({
                              ...formData,
                              primaryMonthandYearOfPassingCalendar:
                                !formData.primaryMonthandYearOfPassingCalendar,
                            })
                          }
                        />
                      </Row>
                      {formData.primaryMonthandYearOfPassingCalendar && (
                        <Calendar
                          view={"year"}
                          onClickMonth={(e) => {
                            setFormData({
                              ...formData,
                              primaryMonthandYearOfPassing:
                                e.toLocaleString("default", {
                                  month: "long",
                                  year: "numeric",
                                }),
                              primaryMonthandYearOfPassingCalendar: false,
                            });
                          }}
                        />
                      )}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>(ii)</td>
                  <td>English</td>
                  <td>
                    <ItemForm
                      type='number'
                      max={formData.maximumMark}
                      value={formData.english}
                      name='english'
                      onChange={(e) =>
                        e.target.value <= formData.maximumMark &&
                        setFormData({
                          ...formData,
                          english: e.target.value,
                        })
                      }
                      onBlur={(e) => {
                        setFormData({
                          ...formData,
                          total:
                            Number(formData.primaryLanguage) +
                            Number(formData.english) +
                            Number(formData.mathematics) +
                            Number(formData.science) +
                            Number(formData.socialScience) +
                            Number(formData.elective),
                        });
                      }}
                    />
                  </td>
                  <td>{formData.maximumMark}</td>
                  <td>
                    <div className='calendar-input'>
                      <Row>
                        <ItemForm
                          value={
                            formData.englishMonthandYearOfPassing
                          }
                          disabled
                          name='englishMonthandYearOfPassing'
                        />
                        <Calendar3
                          className='calendar-input__icon'
                          size='20'
                          onClick={(e) =>
                            setFormData({
                              ...formData,
                              englishMonthandYearOfPassingCalendar:
                                !formData.englishMonthandYearOfPassingCalendar,
                            })
                          }
                        />
                      </Row>
                      {formData.englishMonthandYearOfPassingCalendar && (
                        <Calendar
                          view={"year"}
                          onClickMonth={(e) => {
                            setFormData({
                              ...formData,
                              englishMonthandYearOfPassing:
                                e.toLocaleString("default", {
                                  month: "long",
                                  year: "numeric",
                                }),
                              englishMonthandYearOfPassingCalendar: false,
                            });
                          }}
                        />
                      )}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>(iii)</td>
                  <td>Mathematics</td>
                  <td>
                    <ItemForm
                      type='number'
                      max={formData.maximumMark}
                      value={formData.mathematics}
                      name='mathematics'
                      onChange={(e) =>
                        e.target.value <= formData.maximumMark &&
                        setFormData({
                          ...formData,
                          mathematics: e.target.value,
                        })
                      }
                      onBlur={(e) => {
                        setFormData({
                          ...formData,
                          total:
                            Number(formData.primaryLanguage) +
                            Number(formData.english) +
                            Number(formData.mathematics) +
                            Number(formData.science) +
                            Number(formData.socialScience) +
                            Number(formData.elective),
                        });
                      }}
                    />
                  </td>
                  <td>{formData.maximumMark}</td>
                  <td>
                    <div className='calendar-input'>
                      <Row>
                        <ItemForm
                          value={
                            formData.mathematicsMonthandYearOfPassing
                          }
                          disabled
                          name='mathematicsMonthandYearOfPassing'
                        />
                        <Calendar3
                          className='calendar-input__icon'
                          size='20'
                          onClick={(e) =>
                            setFormData({
                              ...formData,
                              mathematicsMonthandYearOfPassingCalendar:
                                !formData.mathematicsMonthandYearOfPassingCalendar,
                            })
                          }
                        />
                      </Row>
                      {formData.mathematicsMonthandYearOfPassingCalendar && (
                        <Calendar
                          view={"year"}
                          onClickMonth={(e) => {
                            setFormData({
                              ...formData,
                              mathematicsMonthandYearOfPassing:
                                e.toLocaleString("default", {
                                  month: "long",
                                  year: "numeric",
                                }),
                              mathematicsMonthandYearOfPassingCalendar: false,
                            });
                          }}
                        />
                      )}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>(iv)</td>
                  <td>
                    {formData.formType === "First_Year"
                      ? "Science"
                      : "Physics"}
                  </td>
                  <td>
                    <ItemForm
                      type='number'
                      max={formData.maximumMark}
                      value={formData.science}
                      name={
                        formData.formType === "First_Year"
                          ? "science"
                          : "physics"
                      }
                      onChange={(e) =>
                        e.target.value <= formData.maximumMark &&
                        setFormData({
                          ...formData,
                          science: e.target.value,
                        })
                      }
                      onBlur={(e) => {
                        setFormData({
                          ...formData,
                          total:
                            Number(formData.primaryLanguage) +
                            Number(formData.english) +
                            Number(formData.mathematics) +
                            Number(formData.science) +
                            Number(formData.socialScience) +
                            Number(formData.elective),
                        });
                      }}
                    />
                  </td>
                  <td>{formData.maximumMark}</td>
                  <td>
                    <div className='calendar-input'>
                      <Row>
                        <ItemForm
                          value={
                            formData.scienceMonthandYearOfPassing
                          }
                          disabled
                          name='scienceMonthandYearOfPassing'
                        />
                        <Calendar3
                          className='calendar-input__icon'
                          size='20'
                          onClick={(e) =>
                            setFormData({
                              ...formData,
                              scienceMonthandYearOfPassingCalendar:
                                !formData.scienceMonthandYearOfPassingCalendar,
                            })
                          }
                        />
                      </Row>
                      {formData.scienceMonthandYearOfPassingCalendar && (
                        <Calendar
                          view={"year"}
                          onClickMonth={(e) => {
                            setFormData({
                              ...formData,
                              scienceMonthandYearOfPassing:
                                e.toLocaleString("default", {
                                  month: "long",
                                  year: "numeric",
                                }),
                              scienceMonthandYearOfPassingCalendar: false,
                            });
                          }}
                        />
                      )}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>(v)</td>
                  <td>
                    {formData.formType === "First_Year"
                      ? "Social Science"
                      : "Chemistry"}
                  </td>
                  <td>
                    <ItemForm
                      type='number'
                      max={formData.maximumMark}
                      value={formData.socialScience}
                      name={
                        formData.formType === "First_Year"
                          ? "socialScience"
                          : "chemistry"
                      }
                      onChange={(e) =>
                        e.target.value <= formData.maximumMark &&
                        setFormData({
                          ...formData,
                          socialScience: e.target.value,
                        })
                      }
                      onBlur={(e) => {
                        setFormData({
                          ...formData,
                          total:
                            Number(formData.primaryLanguage) +
                            Number(formData.english) +
                            Number(formData.mathematics) +
                            Number(formData.science) +
                            Number(formData.socialScience) +
                            Number(formData.elective),
                        });
                      }}
                    />
                  </td>
                  <td>{formData.maximumMark}</td>
                  <td>
                    <div className='calendar-input'>
                      <Row>
                        <ItemForm
                          value={
                            formData.socialScienceMonthandYearOfPassing
                          }
                          disabled
                          name='socialScienceMonthandYearOfPassing'
                        />
                        <Calendar3
                          className='calendar-input__icon'
                          size='20'
                          onClick={(e) =>
                            setFormData({
                              ...formData,
                              socialScienceMonthandYearOfPassingCalendar:
                                !formData.socialScienceMonthandYearOfPassingCalendar,
                            })
                          }
                        />
                      </Row>
                      {formData.socialScienceMonthandYearOfPassingCalendar && (
                        <Calendar
                          view={"year"}
                          onClickMonth={(e) => {
                            setFormData({
                              ...formData,
                              socialScienceMonthandYearOfPassing:
                                e.toLocaleString("default", {
                                  month: "long",
                                  year: "numeric",
                                }),
                              socialScienceMonthandYearOfPassingCalendar: false,
                            });
                          }}
                        />
                      )}
                    </div>
                  </td>
                </tr>
                {formData.electiveAvailable && (
                  <tr>
                    <td>(vi)</td>
                    <td>Elective (if any)</td>
                    <td>
                      <ItemForm
                        type='number'
                        max={formData.maximumMark}
                        value={formData.elective}
                        name='elective'
                        onChange={(e) =>
                          e.target.value <= formData.maximumMark &&
                          setFormData({
                            ...formData,
                            elective: e.target.value,
                          })
                        }
                        onBlur={(e) => {
                          setFormData({
                            ...formData,
                            total:
                              Number(formData.primaryLanguage) +
                              Number(formData.english) +
                              Number(formData.mathematics) +
                              Number(formData.science) +
                              Number(formData.socialScience) +
                              Number(formData.elective),
                          });
                        }}
                      />
                    </td>
                    <td>{formData.maximumMark}</td>
                    <td>
                      <div className='calendar-input'>
                        <Row>
                          <ItemForm
                            value={
                              formData.electiveMonthandYearOfPassing
                            }
                            disabled
                            name='electiveMonthandYearOfPassing'
                          />
                          <Calendar3
                            className='calendar-input__icon'
                            size='20'
                            onClick={(e) =>
                              setFormData({
                                ...formData,
                                electiveMonthandYearOfPassingCalendar:
                                  !formData.electiveMonthandYearOfPassingCalendar,
                              })
                            }
                          />
                        </Row>
                        {formData.electiveMonthandYearOfPassingCalendar && (
                          <Calendar
                            view={"year"}
                            onClickMonth={(e) => {
                              setFormData({
                                ...formData,
                                electiveMonthandYearOfPassing:
                                  e.toLocaleString("default", {
                                    month: "long",
                                    year: "numeric",
                                  }),
                                electiveMonthandYearOfPassingCalendar: false,
                              });
                            }}
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                )}
                <tr>
                  <td></td>
                  <td>TOTAL</td>
                  <td>{formData.total}</td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
      <div style={{ marginBottom: "20px" }}>
        <Typography variant='h6'>
          Details of studies during last 2 years
        </Typography>
        <div style={{ overflowX: "auto" }}>
          <table>
            <thead>
              <tr>
                <th>SI.No</th>
                <th>Class</th>
                <th>Month and Year of Passing</th>
                <th>
                  {formData.formType === "First_Year"
                    ? "SSLC / Matriculation / I.S.C Reg. No."
                    : "HSC Academic / HSC Vocational Reg. No"}
                </th>
                <th>Name and Address of the School</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1.</td>
                <td>
                  {" "}
                  {formData.formType === "First_Year"
                    ? "IX Std"
                    : "XI Std"}
                </td>
                <td>
                  <div className='calendar-input'>
                    <Row>
                      <ItemForm
                        value={formData.ninthMonthandYearOfPassing}
                        disabled
                        name='ninthMonthandYearOfPassing'
                      />
                      <Calendar3
                        className='calendar-input__icon'
                        size='20'
                        onClick={(e) =>
                          setFormData({
                            ...formData,
                            ninthMonthandYearOfPassingCalendar:
                              !formData.ninthMonthandYearOfPassingCalendar,
                          })
                        }
                      />
                    </Row>
                    {formData.ninthMonthandYearOfPassingCalendar && (
                      <Calendar
                        view={"year"}
                        onClickMonth={(e) => {
                          setFormData({
                            ...formData,
                            ninthMonthandYearOfPassing:
                              e.toLocaleString("default", {
                                month: "long",
                                year: "numeric",
                              }),
                            ninthMonthandYearOfPassingCalendar: false,
                          });
                        }}
                      />
                    )}
                  </div>
                </td>
                <td>
                  <ItemForm
                    value={formData.ninthReg_No}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        ninthReg_No: e.target.value,
                      })
                    }
                    name='ninthReg_No'
                  />
                </td>
                <td>
                  <textarea
                    value={formData.ninthNameAndAddressOftheSchool}
                    rows='5'
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        ninthNameAndAddressOftheSchool:
                          e.target.value,
                      })
                    }
                    name='ninthNameAndAddressOftheSchool'
                  ></textarea>
                </td>
              </tr>
              <tr>
                <td>2.</td>
                <td>
                  {formData.formType === "First_Year"
                    ? "X Std"
                    : "XII Std"}
                </td>
                <td>
                  <div className='calendar-input'>
                    <Row>
                      <ItemForm
                        value={formData.tenthMonthandYearOfPassing}
                        disabled
                        name='tenthMonthandYearOfPassing'
                      />
                      <Calendar3
                        className='calendar-input__icon'
                        size='20'
                        onClick={(e) =>
                          setFormData({
                            ...formData,
                            tenthMonthandYearOfPassingCalendar:
                              !formData.tenthMonthandYearOfPassingCalendar,
                          })
                        }
                      />
                    </Row>
                    {formData.tenthMonthandYearOfPassingCalendar && (
                      <Calendar
                        view={"year"}
                        onClickMonth={(e) => {
                          setFormData({
                            ...formData,
                            tenthMonthandYearOfPassing:
                              e.toLocaleString("default", {
                                month: "long",
                                year: "numeric",
                              }),
                            tenthMonthandYearOfPassingCalendar: false,
                          });
                        }}
                      />
                    )}
                  </div>
                </td>
                <td>
                  <ItemForm
                    value={formData.tenthReg_No}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        tenthReg_No: e.target.value,
                      })
                    }
                    name='tenthReg_No'
                  />
                </td>
                <td>
                  {" "}
                  <textarea
                    value={formData.tenthNameAndAddressOftheSchool}
                    rows='5'
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        tenthNameAndAddressOftheSchool:
                          e.target.value,
                      })
                    }
                    name='tenthNameAndAddressOftheSchool'
                  ></textarea>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <Row h_align='flex-end' gap='2'>
        <Button variant='primary' onClick={previous}>
          Previous
        </Button>
        <Button
          variant='primary'
          onClick={next}
          disabled={buttonDisabled ? true : false}
        >
          Next
        </Button>
      </Row>
    </Section>
  );
};

export default AcademicDetailsScreen;
