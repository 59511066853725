import React, { useState } from 'react';
import Col from './Col';
import Row from './Row';
import Typography from './Typography';
import Feature1 from '../images/courses/civil/img-11.jpg';
import Feature2 from '../images/courses/eee/img-4.jpg';
import Feature3 from '../images/courses/ec/img-5.jpg';
import Feature4 from '../images/courses/cse/img-7.jpg';

import Shape2 from '../images/diamond-green-dots.svg';
import Shape3 from '../images/triangle-yellow-dots.svg';
import Shape4 from '../images/yellow-diamond.svg';
import Shape5 from '../images/red-zigzag.svg';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const HomeFeature = () => {
  const [activeFeature, setActiveFeature] = useState(1);
  const cards = [
    {
      icon: '',
      header: 'Learn with experts',
      content: 'With an expert instructor, we make sure your course going well',
      active: false,
    },
    {
      icon: '',
      header: 'Learn Anything',
      content: 'Whatever you want to develop as a professional',
      active: false,
    },
    {
      icon: '',
      header: 'Flexible Learning',
      content: 'With innovative teaching, we give you job oriented learning',
      active: false,
    },
    {
      icon: '',
      header: 'Industrial Standard',
      content: 'Well Equipped Labs makes your learning more practical',
      active: false,
    },
  ];
  cards[activeFeature].active = true;
  return (
    <Row v_align="center">
      <img
        src={Shape2}
        alt=""
        className="feature__image--abstract feature__image--abstract-2"
      />
      <img
        src={Shape3}
        alt=""
        className="feature__image--abstract feature__image--abstract-3"
      />
      <img
        src={Shape4}
        alt=""
        className="feature__image--abstract feature__image--abstract-4"
      />
      <img
        src={Shape5}
        alt=""
        className="feature__image--abstract feature__image--abstract-5"
      />
      <Col span="2">
        <Typography variant="h2">
          Find out more about us <span>Co-learnings Experience</span>
        </Typography>
        <Typography variant="body1">
          We believe everyone sholud have the opportunity to create progress
          through technology and develop the skills.
        </Typography>
        <Carousel
          showArrows={false}
          showIndicators={false}
          showStatus={false}
          showThumbs={false}
          infiniteLoop={true}
          autoPlay={true}
          onChange={(e) => setActiveFeature(e)}
        >
          <img
            src={Feature1}
            alt="Feature1"
            className="feature__image"
            width="100%"
          />
          <img
            src={Feature2}
            alt="Feature2"
            className="feature__image"
            width="100%"
          />
          <img
            src={Feature3}
            alt="Feature3"
            className="feature__image"
            width="100%"
          />
          <img
            src={Feature4}
            alt="Feature4"
            className="feature__image"
            width="100%"
          />
        </Carousel>
      </Col>
      <Col span="2">
        <ul className="feature__texts-container">
          {cards.map((card, i) => (
            <li
              className={`feature__card ${
                card.active ? 'active' : ''
              } feature__card--${i + 1}`}
              key={i}
            >
              <div className="feature__card-icon">{card.icon}</div>
              <div className="feature__card-text">
                <Typography variant="h6">{card.header}</Typography>
                <Typography variant="body1">{card.content}</Typography>
              </div>
            </li>
          ))}
        </ul>
      </Col>
    </Row>
  );
};

export default HomeFeature;
