import React, { useEffect, useState } from 'react';
import {
  Check2,
  EnvelopeFill,
  GeoAltFill,
  PhoneFill,
  TelephoneFill,
  X,
} from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { sendContactUsMail } from '../actions/taskActions';
import { SEND_CONTACT_US_MAIL_RESET } from '../constants/taskConstants';
import logo from '../images/Kmptc.png';
import Button from './Button';
import Col from './Col';
import ItemForm from './forms/ItemForm';
import MessageBox from './MessageBox';
import Row from './Row';
import Spinner from './Spinner';
import Typography from './Typography';

const Footer = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const { loading, success, error } = useSelector(
    (state) => state.contactUsMail
  );
  const dispatch = useDispatch();
  const submitHandler = (e) => {
    e.preventDefault();
    const validationExp =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (
      name !== null &&
      name !== 'undefined' &&
      email !== null &&
      email !== 'undefined' &&
      message !== null &&
      message !== 'undefined'
    ) {
      if (email.match(validationExp)) {
        dispatch(sendContactUsMail({ name, email, message }));
      } else {
        alert('Please enter valid email address');
      }
    } else {
      alert('Please fill all fields');
    }
  };

  useEffect(() => {
    if (success || error) {
      const timer = setTimeout(() => {
        dispatch({ type: SEND_CONTACT_US_MAIL_RESET });
      }, 5000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [success, error, dispatch]);
  return (
    <footer>
      <Row h_align='space-between'>
        <Col col='4'>
          <div>
            <Link to='/'>
              <img
                src={logo}
                alt='KMPTC'
                width='70px'
                height='70px'
              />
            </Link>
          </div>
          {/* <Typography variant="h5">
            Kottai Mariyamman Polytechnic College
          </Typography> */}
          <Typography style={{ textAlign: 'justify' }}>
            KMPTC-Kottai Mariyamman Polytechnic College is located in
            Salem, Tamil Nadu and affiliated with Directorate of
            Technical Education. The college aims to strengthening
            ability of reconstructing new era of youth society by
            creative and innovative teaching and organizing
            engineering education programs.
          </Typography>
        </Col>

        <Col className='' col='4'>
          <div>
            <Typography variant='h5'>Contact Us</Typography>
          </div>
          <div>
            <Row wrap='nowrap'>
              <GeoAltFill
                size='15'
                style={{ marginRight: '10px', marginTop: '5px' }}
              />
              <div>
                45-A ,Karipatti (Via), <br /> Minnampalli (PO),
                <br />
                Minnampalli,Salem
                <br />
                Tamilnadu( East ),India-636106
              </div>
            </Row>
            <Row wrap='nowrap'>
              <EnvelopeFill
                size='15'
                style={{ marginRight: '10px', marginTop: '5px' }}
              />
              <div>
                <a href='mailto:kottaimari@gmail.com'>
                  kottaimari@gmail.com
                </a>
              </div>
            </Row>
            <Row wrap='nowrap'>
              <TelephoneFill
                size='15'
                style={{ marginRight: '10px', marginTop: '5px' }}
              />
              <div>
                <a href='tel:+04272492538'>0427-2492538</a>,
                <br />
                <a href='tel:+04272482848'> 0427-2482848</a>
              </div>
            </Row>
            <Row wrap='nowrap'>
              <PhoneFill
                size='15'
                style={{ marginRight: '10px', marginTop: '5px' }}
              />
              <div>
                <a href='tel:+918012507075'>+91-8012507075</a>
                <br />
                <a href='tel:+919940301069'> +91-9940301069</a>
              </div>
            </Row>
          </div>
        </Col>
        <Col className='' col='4'>
          <div>
            <Typography variant='h5'>Useful Links</Typography>
          </div>
          <ul className='links'>
            <li>
              <Link to='/about'>About</Link>
            </li>
            <li>
              <Link to='/courses'>Courses</Link>
            </li>
            <li>
              <Link to='/facilities'>Facilities</Link>
            </li>
            <li>
              <Link to='/press-realease'>Press Release</Link>
            </li>
            <li>
              <Link to='/gallery'>Gallery</Link>
            </li>

            <li>
              <Link to='/contact-us'>Contact Us</Link>
            </li>
          </ul>
        </Col>
        <Col col='4'>
          <div>
            <Typography variant='h5'>Feedback / Mail Us</Typography>
          </div>
          <form
            className='contact-us__form form'
            onSubmit={submitHandler}
          >
            <Row>
              <ItemForm
                placeholder='Enter Name'
                name='name'
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </Row>
            <Row>
              <ItemForm
                type='email'
                name='email'
                value={email}
                placeholder='Enter Email'
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Row>
            <Row>
              <textarea
                type='text'
                required
                rows='4'
                value={message}
                placeholder='Enter Message'
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
              ></textarea>
            </Row>
            <Row>
              <Button
                variant={error ? 'error' : 'primary'}
                style={{ display: 'block', width: '100%' }}
              >
                {loading ? (
                  <Spinner />
                ) : error ? (
                  <Row wrap='nowrap'>
                    <X
                      size='20'
                      style={{ marginRight: '1rem' }}
                      color='white'
                    />
                    <span>Failure</span>
                  </Row>
                ) : success ? (
                  <Row wrap='nowrap'>
                    <Check2
                      size='20'
                      style={{ marginRight: '1rem' }}
                      color='white'
                      fill='white'
                    />
                    <span>Success</span>
                  </Row>
                ) : (
                  'Send'
                )}
              </Button>
            </Row>
            {error && (
              <MessageBox variant='error'>{error}</MessageBox>
            )}
          </form>
        </Col>
      </Row>
      <Row h_align='center'>
        <div>
          &#169; {new Date().getFullYear()} Copyright KMPTC. All
          rights reserved | Designed and Developed By{' '}
          <Link
            to='/dazzexports'
            target='_blank'
            className='typography--color-primary'
            style={{ transform: 'translateX(10px)' }}
          >
            DazzExports
          </Link>
        </div>
      </Row>
    </footer>
  );
};

export default Footer;
