import React from 'react';
import Section from '../components/Section';
import Row from '../components/Row';
import Typography from '../components/Typography';
import Chairman from '../images/Chairman.jpg';
import Secretary from '../images/Secretary.jpg';
import Treasurer from '../images/Treasurer.jpg';
import Principal from '../images/Principal.jpg';
import College from '../images/kmptc-fullview.jpg';
import { CaretRightFill } from 'react-bootstrap-icons';
const AboutScreen = () => {
  return (
    <Section className="section__about-us">
      <img
        src={College}
        alt="KMPTC"
        style={{ width: '100%', borderRadius: '2rem' }}
      />{' '}
      <Typography variant="h2">About Us</Typography>
      <Typography
        variant="body1"
        style={{ textAlign: 'justify', marginBottom: '3rem' }}
      >
        KMPTC - Kottai Mariyamman Polytechnic College, has been started on June
        2008 by the Kottai Mariyamman Educational Trust to provide quality
        education and technical competencies for the upcoming generation.
        Equipped with world class infrastructure and well experienced faculty
        members to offer a strong foundation of Ethics, Values and rich Culture
        to the students. A very active and entusiastic placement cell is
        functioning with a target of placing the students in MNCs. College is
        offering Diploma in Civil Engineering, Mechanical Engineering,
        Automobile Engineering, Electrical and Electronics Engineering,
        Electronics and Communication Engineering and Computer Engineering.
      </Typography>
      <div className="about-us__management">
        <Typography variant="h4" style={{ textAlign: 'center' }}>
          <span>Golden Words from Our Management People</span>
        </Typography>

        <Row
          className="about-us__management-people"
          v_align="center"
          h_align="space-between"
        >
          <div className="about-us__management-peopleImg">
            <img src={Chairman} alt={'Chairman'} />
          </div>

          <div className="about-us__management-peopleText">
            <div style={{ marginBottom: '2rem' }}>
              <Typography variant="h4">Thiru K.Palaniyappan</Typography>
              <Typography variant="h6">Chairman</Typography>
            </div>
            <em>
              <Typography>
                We believe that the culture and education of the students can be
                developed through proper guidance and counseling.The management
                believes that this college education is the foundation for all
                future possibilities in the life of a student. Hence the
                academic grounding and moral strength gained by the education
                provided by the institution enable the student to compete in the
                career.
              </Typography>
            </em>
          </div>
        </Row>
        <Row
          className="about-us__management-people"
          v_align="center"
          h_align="space-between"
        >
          <div className="about-us__management-peopleText left">
            <div style={{ marginBottom: '2rem' }}>
              <Typography variant="h4">Thiru P.Kalaiselvan, DME.</Typography>
              <Typography variant="h6">Secretary</Typography>
            </div>
            <em>
              <Typography>
                The focus on the development of women students women staff and
                women in community to uplift their social, professional and
                economic status.Active participation of students with the expert
                guidance from faculty members at the class rooms and
                laboratories will enable them to grasp the finer points of
                engineering practices.
              </Typography>
            </em>
          </div>

          <div className="about-us__management-peopleImg right">
            <img src={Secretary} alt={'Secretary'} />
          </div>
        </Row>
        <Row
          className="about-us__management-people"
          v_align="center"
          h_align="space-between"
        >
          <div className="about-us__management-peopleImg">
            <img src={Treasurer} alt={'Treasurer'} />
          </div>

          <div className="about-us__management-peopleText">
            <div style={{ marginBottom: '2rem' }}>
              <Typography variant="h4">Thiru R.Selvakumar, MBA.</Typography>
              <Typography variant="h6">Tresurer</Typography>
            </div>
            <em>
              <Typography>
                we offer encouragement awards and scholarship to poor and
                deserving students.It is our commitment to groom budding
                professionals with utmost discipline and human values.We
                encourage each child to give their very best in an attempt to
                realise their dreams.
              </Typography>
            </em>
          </div>
        </Row>
        <Row
          className="about-us__management-people"
          v_align="center"
          h_align="space-between"
        >
          <div className="about-us__management-peopleText left">
            <div style={{ marginBottom: '2rem' }}>
              <Typography variant="h4">
                Thiru Er.S.Mohan, BE(Mech)., M.S(Tech.Oper.)., MIR., MISTE., CE
                (India).
              </Typography>
              <Typography variant="h6">Principal</Typography>
            </div>
            <em>
              <Typography>
                We offer a timely and useful Technology for all students that
                matches industrial needs and social expectations.We have the
                broad vision of developing the most ordinary of the students
                into technical professionals, who will remain relevant in the
                current as well as the future global landscape. Our objective is
                to develop confidence, self-motivation, research and problem
                solving skill sets and imbibe value education among our
                students. We are confident that the students passing out from
                this college will be technically sound, confident and have good
                leadership skills.
              </Typography>
            </em>
          </div>

          <div className="about-us__management-peopleImg right">
            <img src={Principal} alt={'Principal'} />
          </div>
        </Row>
      </div>{' '}
      <Row className="about-us__vision" v_align="center">
        <Typography variant="h2">
          Our <span>Vision</span>
        </Typography>

        <Typography variant="body1">
          The vision of KOTTAI MARIYAMMAN POLYTECHNIC COLLEGE is to be the
          leading centre of learning and innovation in emerging areas of
          business and management education. For every course the college
          imparts practical training to its students that helps them to
          understand better, improve their demonstrative skills, eliminate
          hesitation and in this process strives to mould them into industry
          ready professionals.
        </Typography>
      </Row>
      <Row className="about-us__mission" v_align="center">
        <Typography variant="h2">
          Our <span>Mission</span>
        </Typography>
        <Typography variant="body1">
          Mission of KOTTAI MARIYAMMAN POLYTECHNIC COLLEGE is 'to foster
          critical analysis by training students both in theory and practice so
          as to equip them with skills needed for facing global challenges with
          team work and human values. And to build quality network with the
          Industry, educationists and the society for exchanging knowledge'. The
          modern teaching methodology assists the students in getting an all
          around view of engineering, technology and the entrepreneur world. The
          diligent faculty team of the college possesses hands-on experience and
          up to date knowledge of their subject and area of expertise.{' '}
        </Typography>
      </Row>
      <Row className="about-us__quality-policy" v_align="center">
        <Typography variant="h2">
          Our <span>Quality Policy</span>
        </Typography>
        <Typography variant="body1">
          We, the KOTTAI MARIYAMMAN POLYTECHNIC COLLEGE work to achieve
          stakeholders satisfaction by providing quality education and training
          through
          <ul style={{ padding: '1rem' }}>
            <Row
              wrap="nowrap"
              v_align="center"
              style={{ marginBottom: '1rem' }}
            >
              <CaretRightFill
                size="15"
                style={{ marginRight: '10px' }}
                className="list__icons"
              />
              <li>Proper Counselling</li>
            </Row>
            <Row
              wrap="nowrap"
              v_align="center"
              style={{ marginBottom: '1rem' }}
            >
              <CaretRightFill
                size="15"
                style={{ marginRight: '10px' }}
                className="list__icons"
              />
              <li>Dedicated Teaching and Excellent Coaching</li>
            </Row>
            <Row
              wrap="nowrap"
              v_align="center"
              style={{ marginBottom: '1rem' }}
            >
              <CaretRightFill
                size="15"
                style={{ marginRight: '10px' }}
                className="list__icons"
              />
              <li>Full-fledged Laboratories</li>
            </Row>
          </ul>
        </Typography>
      </Row>
    </Section>
  );
};

export default AboutScreen;
