import React, { useEffect, useRef, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { ArrowLeftShort, ArrowRightShort } from 'react-bootstrap-icons';
import Row from './Row';
import Button from './Button';
import Typography from './Typography';
import Testmonial1 from '../images/facilities/library/lib-8.jpg';
import Testmonial2 from '../images/courses/cse/img-11.jpg';
import Testmonial3 from '../images/facilities/hostel/girl-hostel-4.jpg';
import Testmonial4 from '../images/facilities/Lab/lab8.jpg';

import Col from './Col';

const Testimonials = () => {
  const studentsTrackRef = useRef();

  const [activeStudent, setActiveStudent] = useState(0);
  const [prevActiveStudent, setPrevActiveStudent] = useState(0);
  useEffect(() => {
    studentsTrackRef.current.childNodes[prevActiveStudent].classList.remove(
      'active'
    );
    studentsTrackRef.current.childNodes[activeStudent].classList.add('active');
  }, [activeStudent]);

  const carouselRef = useRef();
  const handlePrev = () => {};
  return (
    <div className="testmonial-container">
      <Row v_align="center">
        <Col span="2">
          <ul className="testmonial__image-container" ref={studentsTrackRef}>
            <li className="testmonial__image--outer active">
              <img
                src={Testmonial1}
                alt="testmonial-1"
                className="testmonial__image"
              />
            </li>
            <li className="testmonial__image--outer">
              <img
                src={Testmonial2}
                alt="testmonial-2"
                className="testmonial__image"
              />
            </li>
            <li className="testmonial__image--outer">
              <img
                src={Testmonial3}
                alt="testmonial-3"
                className="testmonial__image"
              />
            </li>
            <li className="testmonial__image--outer">
              <img
                src={Testmonial4}
                alt="testmonial-4"
                className="testmonial__image"
              />
            </li>
          </ul>
        </Col>
        <Col span="2">
          <div>
            <Typography variant="h2">
              Check What <span> Our Students say</span>
            </Typography>
            <Carousel
              showIndicators={false}
              showStatus={false}
              showThumbs={false}
              infiniteLoop={true}
              interval={10000}
              autoPlay={true}
              showArrows={false}
              onChange={(e) => {
                setPrevActiveStudent(activeStudent);
                setActiveStudent(e);
              }}
              ref={carouselRef}
            >
              <div className="testmonial__slide testmonial__slide--1">
                Our Sports coach’s and PD faculties training given to us, thus
                really help to achieved a lot of inter colleges / other state /
                national level sports games awards by all of sports teams /
                individuals.
              </div>
              <div className="testmonial__slide testmonial__slide--1">
                Whatever our technical faculties training / lab sessions given
                to us thus help to empowered and innovated new things during
                academic Internship/Projects.
              </div>
              <div className="testmonial__slide testmonial__slide--1">
                I would say about teching faculties what taught us that really
                very helpful in academic exams and also able to understand
                outside trending technologies.
              </div>
              <div className="testmonial__slide testmonial__slide--1">
                Management Team / Cultural team faculty’s guidance and
                motivation really help us to participate and win in multiple
                intercollege / national colleges cultural functions, spared our
                contributions in various social development activities as well
                which helped to develop and enhance our society growing up.
              </div>
            </Carousel>
            <Row h_align="center" gap="1">
              <Button
                style={{ padding: '0rem', height: '2.4rem' }}
                variant="outlined"
                onClick={(e) => {
                  carouselRef.current.decrement();
                }}
              >
                <ArrowLeftShort size="24" cursor="pointer" />
              </Button>
              <Button
                variant="primary"
                style={{ padding: '0rem', height: '2.4rem' }}
                onClick={(e) => {
                  carouselRef.current.increment();
                }}
              >
                <ArrowRightShort size="24" cursor="pointer" />
              </Button>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Testimonials;
