import React from 'react';

const Row = ({
  children,
  v_align,
  h_align,
  gap,
  padding,
  col,
  wrap,
  ...props
}) => {
  return (
    <div
      className={`row  ${props.className ? props.className : ''}`}
      style={{
        justifyContent: h_align ? h_align : 'flex-start',
        alignItems: v_align ? v_align : 'flex-start',
        padding: padding ? padding : '',
        flexWrap: wrap ? wrap : 'wrap',
        gap: gap ? gap + 'rem' : '',
        ...props.style,
      }}
    >
      {children}
    </div>
  );
};

export default Row;
