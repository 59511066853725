import React from 'react';
import { X } from 'react-bootstrap-icons';

export default function Modal(props) {
  const { closeModal } = props;
  const closeicon = () => (
    <X
      name="times"
      onClick={closeModal}
      style={{
        color: '#000000',
        padding: '10px',
        cursor: 'pointer',
        backgroundColor: 'transparent',
        border: 0,
        position: 'absolute',
        top: '0.3rem',
        right: '0.5rem',
      }}
    />
  );
  return (
    <div className="modal">
      <div className="modal__content">
        {!props.closeIcon ? '' : closeicon()}
        {props.children}
      </div>
    </div>
  );
}
