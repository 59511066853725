import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Card, { CardContent, CardHeader, CardMedia } from './Card';
import Col from './Col';
import Row from './Row';
import Typography from './Typography';
import Civil from '../images/courses/civil/img-5.jpg';
import Computer from '../images/courses/cse/img-2.jpg';
import Mechanical from '../images/courses/mech/img-13.jpg';
import Button from './Button';
import Shape1 from '../images/square-grey-dots.svg';
import Shape5 from '../images/red-zigzag.svg';
import Shape6 from '../images/yellow-dot-single.svg';
import Shape7 from '../images/grey-dot-single.svg';
import Shape8 from '../images/green-dot-single.svg';
import Shape9 from '../images/red-dot-single.svg';

const HomeCourse = () => {
  const [screenWidth, setScreenWidth] = useState('');
  useEffect(() => {
    window.addEventListener('load', () => {
      setScreenWidth(window.innerWidth);
    });
    window.addEventListener('resize', () => {
      setScreenWidth(window.innerWidth);
    });
  }, []);
  return (
    <div>
      <Row v_align="center" h_align="space-between" col="2">
        <div className="courses__text">
          <Typography variant="h2">
            Our Popular <span>Courses</span>
          </Typography>
          <Typography variant="body1">
            We believe everyone sholud have the opportunity to create progress
            through technology and develop the skills.
          </Typography>
        </div>
        <Row v_align="center" h_align="flex-end">
          <Button variant="outlined">
            <Link to="/courses">Browse all</Link>
          </Button>
        </Row>
      </Row>
      <Row
        gap="0.5"
        wrap={screenWidth > 900 ? 'nowrap' : 'wrap'}
        v_align="stretch"
        h_align="space-between"
      >
        <Col col="3">
          <Card borderRadius="2">
            <CardMedia height="17" src={Civil}></CardMedia>
            <CardContent padding="3">
              <CardHeader>Diploma in Civil Engineering (DCE)</CardHeader>
              <Typography variant="body1">
                Kottai Mariamman Polytechnic College, Salem offers 3 Years Full
                Time Diploma / Certification in Diploma Civil Engineering (DCE)
              </Typography>
            </CardContent>
          </Card>
        </Col>
        <Col col="3">
          <Card borderRadius="3">
            <CardMedia height="17" src={Computer}></CardMedia>
            <CardContent padding="2">
              <CardHeader>Diploma in Computer Engineering (DCT)</CardHeader>
              <Typography variant="body1">
                Kottai Mariamman Polytechnic College, Salem offers 3 Years Full
                Time Professional Courses in Diploma in Computer Engineering
                (DCT)
              </Typography>
            </CardContent>
          </Card>
        </Col>
        <Col col="3">
          <Card borderRadius="2">
            <CardMedia height="17" src={Mechanical}></CardMedia>
            <CardContent padding="3">
              <CardHeader>Diploma in Mechanical Engineering (DME)</CardHeader>
              <Typography variant="body1">
                Kottai Mariamman Polytechnic College, Salem offers 2 Years Full
                Time Diploma / Certification in Diploma Mechanical Engineering
                (DME)
              </Typography>
            </CardContent>
          </Card>
        </Col>
      </Row>
      <img
        src={Shape1}
        alt=""
        className="courses__image--abstract courses__image--abstract-1"
      />
      <img
        src={Shape5}
        alt=""
        className="courses__image--abstract courses__image--abstract-2"
      />
      <img
        src={Shape6}
        alt=""
        className="courses__image--abstract courses__image--abstract-3"
      />
      <img
        src={Shape7}
        alt=""
        className="courses__image--abstract courses__image--abstract-4"
      />
      <img
        src={Shape8}
        alt=""
        className="courses__image--abstract courses__image--abstract-5"
      />
      <img
        src={Shape9}
        alt=""
        className="courses__image--abstract courses__image--abstract-6"
      />
    </div>
  );
};

export default HomeCourse;
