import React from "react";

const CheckBox = ({ label, variant, children, ...otherProps }) => {
  return (
    <div className='checkbox'>
      <label>
        <input
          type='checkbox'
          {...otherProps}
          checked={otherProps.checked}
        />
        <span className='checkmark'></span>
        {label}
      </label>
    </div>
  );
};

export default CheckBox;
