import {
  SEND_CONTACT_US_MAIL_ERROR,
  SEND_CONTACT_US_MAIL_LOADING,
  SEND_CONTACT_US_MAIL_SUCCESS,
  SEND_APPLICATION_ERROR,
  SEND_APPLICATION_LOADING,
  SEND_APPLICATION_SUCCESS,
} from "../constants/taskConstants";

import Axios from "axios";
import FileDownload from "js-file-download";
import html2pdf from "html2pdf.js";
export const sendContactUsMail = (formData) => async (dispatch) => {
  const url = `https://kmptc.net/sendMail/index.php?sendto='${formData.email}'&name='${formData.name}'&message='${formData.message}`;
  dispatch({ type: SEND_CONTACT_US_MAIL_LOADING });
  try {
    const data = await Axios.get(url);
    dispatch({ type: SEND_CONTACT_US_MAIL_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: SEND_CONTACT_US_MAIL_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const registerApplication = (html) => async (dispatch) => {
  const url = `https://kmptc.net/sendMail/register.php`;
  dispatch({ type: SEND_APPLICATION_LOADING });
  try {
    const opt = {
      filename: "KMPTC-Application-Form.pdf",
      margin: 10,
      image: { type: "jpeg", quality: 0.9, scale: 0.7 },
      jsPDF: { format: "a4" },
      pagebreak: { after: [".page1", ".page2", ".page3"] },
    };

    let worker = await html2pdf()
      .set(opt)
      .from(html)
      .toPdf()
      .output("datauristring")
      .then((data) => data);

    let fd = new FormData();
    fd.append("file", worker);
    await Axios({
      method: "POST",
      url: url,
      data: fd,
      headers: { "Content-Type": "multipart/form-data" },
      responseType: "blob",
    }).then((res) =>
      FileDownload(res.data, "KMPTC_APPLICATION_FORM.pdf")
    );

    dispatch({
      type: SEND_APPLICATION_SUCCESS,
      payload: "Mail has been sent",
    });
  } catch (error) {
    dispatch({
      type: SEND_APPLICATION_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
