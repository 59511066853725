import {
  SEND_CONTACT_US_MAIL_ERROR,
  SEND_CONTACT_US_MAIL_LOADING,
  SEND_CONTACT_US_MAIL_SUCCESS,
  SEND_CONTACT_US_MAIL_RESET,
  SEND_APPLICATION_LOADING,
  SEND_APPLICATION_SUCCESS,
  SEND_APPLICATION_ERROR,
  SEND_APPLICATION_RESET,
} from "../constants/taskConstants";

export const contactUsMailReducer = (state = {}, action) => {
  switch (action.type) {
    case SEND_CONTACT_US_MAIL_LOADING:
      return { loading: true };
    case SEND_CONTACT_US_MAIL_SUCCESS:
      return { loading: false, success: true };
    case SEND_CONTACT_US_MAIL_ERROR:
      return { loading: false, error: action.payload };
    case SEND_CONTACT_US_MAIL_RESET:
      return {};
    default:
      return state;
  }
};

export const registerApplicationReducer = (state = {}, action) => {
  switch (action.type) {
    case SEND_APPLICATION_LOADING:
      return { loading: true };
    case SEND_APPLICATION_SUCCESS:
      return { loading: false, success: true };
    case SEND_APPLICATION_ERROR:
      return { loading: false, error: action.payload };
    case SEND_APPLICATION_RESET:
      return {};
    default:
      return state;
  }
};
