import React from 'react';
import PuffLoader from 'react-spinners/PuffLoader';
const FallbackContainer = (props) => (
  <div
    style={{
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    {props.children}
  </div>
);
const LoadingScreen = () => {
  return (
    <FallbackContainer>
      <PuffLoader color="#5bb462" />
    </FallbackContainer>
  );
};

export default LoadingScreen;
