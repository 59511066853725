import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';
import { SRLWrapper } from 'simple-react-lightbox';
import Row from '../components/Row';
import Section from '../components/Section';
import Typography from '../components/Typography';
import Gallary1 from '../images/press-release/IMG-20210825-WA0019.jpg';
import Gallary2 from '../images/press-release/IMG-20210825-WA0020.jpg';

const PressReleaseScreen = () => {
  const gallery = [
    {
      src: Gallary1,
    },
    {
      src: Gallary2,
    },
  ];
  const [currentPage, setCurrentPage] = useState(1);

  let imgPerPage = 20;
  let indexOfLastImg = currentPage * imgPerPage;
  let indexOfFirstImg = indexOfLastImg - imgPerPage;
  const currentImgs = gallery.slice(indexOfFirstImg, indexOfLastImg);
  const handleClick = (data) => {
    setCurrentPage(data.selected + 1);
  };
  return (
    <Section>
      <Typography variant="h2">Press Release</Typography>
      <SRLWrapper>
        <div className="gallery">
          {currentImgs.map((item, i) => (
            <figure className={`gallery__item gallery__item--${i + 1}`} key={i}>
              <img src={item.src} alt={i + 1} className="gallery__img" />
            </figure>
          ))}
        </div>
      </SRLWrapper>
      {gallery.length / imgPerPage > 1 && (
        <Row gap="2" h_align="center">
          <ReactPaginate
            previousLabel={'Prev'}
            nextLabel={'Next'}
            pageCount={gallery.length / imgPerPage}
            onPageChange={handleClick}
            pageClassName="btn btn__outlined"
            nextClassName="btn btn__outlined"
            previousClassName="btn btn__outlined"
            activeClassName="btn_primary"
          />
        </Row>
      )}
    </Section>
  );
};

export default PressReleaseScreen;
