import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import React, { useContext, useEffect, useState } from "react";
import Stepper from "react-stepper-horizontal";
import Section from "../components/Section";
import Typography from "../components/Typography";
import Button from "../components/Button";
import Row from "../components/Row";
import { formDataContext } from "../context/FormDataProvider";
const UploadScreen = ({ previous, next }) => {
  const { formData, setFormData } = useContext(formDataContext);

  const [tc, setTc] = useState([]);
  const [photo, setPhoto] = useState([]);
  const [marksheet, setMarkSheet] = useState([]);
  const [windowWidth, setWindowWidth] = useState();
  registerPlugin(FilePondPluginFileValidateSize);
  registerPlugin(FilePondPluginFileValidateType);
  const buttonDisabled = !(
    formData.tenthTc &&
    formData.tenthMarkSheet &&
    formData.applicantPhoto
  );

  useEffect(() => {
    const file = marksheet[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      // use a regex to remove data url part
      const base64String = reader.result
        .replace("data:", "")
        .replace(/^.+,/, "");

      // log to console
      // logs wL2dvYWwgbW9yZ...
      setFormData({ ...formData, tenthMarkSheet: base64String });
    };
    file && reader.readAsDataURL(file);
  }, [marksheet]);
  useEffect(() => {
    const file = tc[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      // use a regex to remove data url part
      const base64String = reader.result
        .replace("data:", "")
        .replace(/^.+,/, "");

      // log to console
      // logs wL2dvYWwgbW9yZ...
      setFormData({ ...formData, tenthTc: base64String });
    };
    file && reader.readAsDataURL(file);
  }, [tc]);
  useEffect(() => {
    const file = photo[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      // use a regex to remove data url part
      const base64String = reader.result
        .replace("data:", "")
        .replace(/^.+,/, "");

      // log to console
      // logs wL2dvYWwgbW9yZ...
      setFormData({ ...formData, applicantPhoto: base64String });
    };
    file && reader.readAsDataURL(file);
  }, [photo]);
  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };
  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () =>
      window.removeEventListener("resize", updateDimensions);
  }, []);

  const steps =
    windowWidth > 600
      ? [
          { title: "Personal Details" },
          { title: "Address Details" },
          { title: "School Details" },
          { title: "Academic Details" },
          { title: "Upload Files" },
          { title: "Confirmation" },
        ]
      : [
          { title: "" },
          { title: "" },
          { title: "" },
          { title: "" },
          { title: "" },
          { title: "" },
        ];
  return (
    <Section>
      <div style={{ marginBottom: "50px" }}>
        <Stepper
          steps={steps}
          activeStep={4}
          activeColor='#5bb462'
          completeColor='#5bb462'
          completeTitleColor='#5bb462'
          activeTitleColor='#5bb462'
          titleFontSize={14}
        />
      </div>
      <Typography variant='h4'>Upload Files</Typography>
      <div>
        <div>Applicant Photo</div>
        <FilePond
          files={photo}
          name='applicantPhoto'
          allowMultiple={false}
          allowFileEncode={true}
          required={true}
          labelIdle={`Drag & Drop your applicant photo or
        <span class="filepond--label-action"> Browse </span>`}
          allowFileTypeValidation={true}
          acceptedFileTypes={["image/png", "image/jpeg"]}
          labelFileTypeNotAllowed='File of invalid type'
          fileValidateTypeLabelExpectedTypes='Expects {allButLastType} or {lastType}'
          fileValidateTypeLabelExpectedTypesMap={{
            "image/png": ".png",
            "image/jpeg": ".jpg",
          }}
          fileValidateTypeDetectType='Expects {allButLastType} or {lastType}'
          allowFileSizeValidation={true}
          maxFileSize='1mb'
          labelMaxFileSize='Maximum file size is {filesize}'
          labelMaxFileSizeExceeded='File is too large'
          onupdatefiles={(files) => {
            files.length > 0 && setPhoto(files.map((f) => f.file));

            files.length > 0 &&
              typeof files[0].getFileEncodeBase64String() !==
                "undefined" &&
              setFormData({
                ...formData,
                applicantPhoto: files[0].getFileEncodeBase64String(),
              });
          }}
        />
      </div>
      <div>
        <div>{`${
          formData.formType === "First_Year" ? "Tenth" : "Twelfth"
        } Marksheet`}</div>
        <FilePond
          files={marksheet}
          name='TenthMarkSheet'
          allowMultiple={false}
          allowFileEncode={true}
          required={true}
          labelIdle={`Drag & Drop your 
        ${
          formData.formType === "First_Year" ? "Tenth" : "Twelfth"
        } Marksheet or <span class="filepond--label-action"> Browse </span>`}
          allowFileTypeValidation={true}
          acceptedFileTypes={["image/png", "image/jpeg"]}
          labelFileTypeNotAllowed='File of invalid type'
          fileValidateTypeLabelExpectedTypes='Expects {allButLastType} or {lastType}'
          fileValidateTypeLabelExpectedTypesMap={{
            "image/png": ".png",
            "image/jpeg": ".jpg",
          }}
          fileValidateTypeDetectType='Expects {allButLastType} or {lastType}'
          allowFileSizeValidation={true}
          maxFileSize='1mb'
          labelMaxFileSize='Maximum file size is {filesize}'
          labelMaxFileSizeExceeded='File is too large'
          onupdatefiles={(files) => {
            files.length > 0 &&
              setMarkSheet(files.map((f) => f.file));

            files.length > 0 &&
              typeof files[0].getFileEncodeBase64String() !==
                "undefined" &&
              setFormData({
                ...formData,
                tenthMarkSheet: files[0].getFileEncodeBase64String(),
              });
          }}
        />
      </div>
      <div>
        <div>{`${
          formData.formType === "First_Year" ? "Tenth" : "Twelfth"
        } TC`}</div>
        <FilePond
          name='TenthTc'
          files={tc}
          allowMultiple={false}
          allowFileEncode={true}
          required={true}
          allowFileTypeValidation={true}
          acceptedFileTypes={["image/png", "image/jpeg"]}
          labelFileTypeNotAllowed='File of invalid type'
          fileValidateTypeLabelExpectedTypes='Expects {allButLastType} or {lastType}'
          fileValidateTypeLabelExpectedTypesMap={{
            "image/png": ".png",
            "image/jpeg": ".jpg",
          }}
          fileValidateTypeDetectType='Expects {allButLastType} or {lastType}'
          allowFileSizeValidation={true}
          maxFileSize='1mb'
          labelMaxFileSize='Maximum file size is {filesize}'
          labelMaxFileSizeExceeded='File is too large'
          onupdatefiles={(files) => {
            files.length > 0 && setTc(files.map((f) => f.file));

            files.length > 0 &&
              typeof files[0].getFileEncodeBase64String() !==
                "undefined" &&
              setFormData({
                ...formData,
                tenthTc: files[0].getFileEncodeBase64String(),
              });
          }}
          maxFiles={1}
          labelIdle={`Drag & Drop your  ${
            formData.formType === "First_Year" ? "Tenth" : "Twelfth"
          } TC or <span class="filepond--label-action"> Browse </span>`}
        />
      </div>
      <Row h_align='flex-end' gap='2'>
        <Button variant='primary' onClick={previous}>
          Previous
        </Button>
        <Button
          variant='primary'
          onClick={next}
          disabled={buttonDisabled ? true : false}
        >
          Next
        </Button>
      </Row>
    </Section>
  );
};

export default UploadScreen;
