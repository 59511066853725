import React, { useContext, useEffect, useState } from "react";
import Stepper from "react-stepper-horizontal/lib/Stepper";
import { FilePond, registerPlugin } from "react-filepond";
import Calendar from "react-calendar";
import { Calendar3 } from "react-bootstrap-icons";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import Button from "../components/Button";
import Col from "../components/Col";
import ItemForm from "../components/forms/ItemForm";
import Select from "../components/forms/Select";
import Row from "../components/Row";
import Section from "../components/Section";
import Typography from "../components/Typography";
import { formDataContext } from "../context/FormDataProvider";
import "filepond/dist/filepond.min.css";

const SchoolDetailsScreen = ({ next, previous }) => {
  const [windowWidth, setWindowWidth] = useState();
  const { formData, setFormData } = useContext(formDataContext);
  registerPlugin(FilePondPluginFileEncode);
  registerPlugin(FilePondPluginFileValidateSize);
  registerPlugin(FilePondPluginFileValidateType);
  const examCodeType = {
    sslc: [
      ["SSLC (10 Years)", "SSLC (10 Years)"],
      ["ISC", "ISC"],
      ["Others", "Others"],
    ],
    hsc: [
      ["HSC Academic", "HSC Academic"],
      ["HSC Vocational", "HSC Vocational"],
      ["Others", "Others"],
    ],
  };
  const buttonDisabled = !(
    formData.SSLCRegistrationNumber &&
    formData.SIofCertficate &&
    formData.examCodeNo &&
    formData.designOfOfficer &&
    formData.yearOfPassing
  );
  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };
  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () =>
      window.removeEventListener("resize", updateDimensions);
  }, []);

  const steps =
    windowWidth > 600
      ? [
          { title: "Personal Details" },
          { title: "Address Details" },
          { title: "School Details" },
          { title: "Academic Details" },
          { title: "Upload Files" },
          { title: "Confirmation" },
        ]
      : [
          { title: "" },
          { title: "" },
          { title: "" },
          { title: "" },
          { title: "" },
          { title: "" },
        ];
  return (
    <Section>
      <div style={{ marginBottom: "50px" }}>
        <Stepper
          steps={steps}
          activeStep={2}
          activeColor='#5bb462'
          completeColor='#5bb462'
          completeTitleColor='#5bb462'
          activeTitleColor='#5bb462'
          titleFontSize={14}
        />
      </div>
      <Typography variant='h4'>School Details</Typography>
      <Row>
        <Col span='2'>
          <div className='form'>
            <ItemForm
              type='text'
              value={formData.SSLCRegistrationNumber}
              name='SSLCRegistrationNumber'
              required
              onChange={(e) => {
                const regExp = /^[0-9\b]+$/;
                if (
                  e.target.value === "" ||
                  regExp.test(e.target.value)
                ) {
                  setFormData({
                    ...formData,
                    SSLCRegistrationNumber: e.target.value,
                  });
                }
              }}
              label={
                formData.formType === "First_Year"
                  ? "SSLC Registration Number"
                  : "HSC Registration Number"
              }
            />
            <ItemForm
              value={formData.SIofCertficate}
              name='SIofCertficate'
              onChange={(e) =>
                setFormData({
                  ...formData,
                  SIofCertficate: e.target.value,
                })
              }
              label='SI.of the Certificate'
            />{" "}
            <Select
              label='Examination Passed'
              name='examCodeNo'
              value={formData.examCodeNo}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  examCodeNo: e.target.value,
                })
              }
              arr={
                formData.formType === "First_Year"
                  ? [...examCodeType.sslc]
                  : [...examCodeType.hsc]
              }
              required
            ></Select>
          </div>
        </Col>
        <Col span='2'>
          <div className='form'>
            <ItemForm
              type='text'
              value={formData.designOfOfficer}
              name='designOfOfficer'
              onChange={(e) =>
                setFormData({
                  ...formData,
                  designOfOfficer: e.target.value,
                })
              }
              label='Designation of the Officer'
              required
            />{" "}
            <div className='calendar-input'>
              <Row>
                <ItemForm
                  value={formData.yearOfPassing}
                  disabled
                  name='yearOfPassing'
                  label='Year of Passing'
                />
                <Calendar3
                  className='calendar-input__icon'
                  size='20'
                  onClick={(e) =>
                    setFormData({
                      ...formData,
                      yearOfPassingCalendar:
                        !formData.yearOfPassingCalendar,
                    })
                  }
                />
              </Row>
              {formData.yearOfPassingCalendar && (
                <Calendar
                  view={"decade"}
                  onClickYear={(e) => {
                    setFormData({
                      ...formData,
                      yearOfPassing: e.toLocaleString("default", {
                        year: "numeric",
                      }),
                      yearOfPassingCalendar: false,
                    });
                  }}
                />
              )}
            </div>
          </div>
        </Col>
      </Row>
      <Row h_align='flex-end' gap='2'>
        <Button variant='primary' onClick={previous}>
          Previous
        </Button>
        <Button
          variant='primary'
          onClick={next}
          disabled={buttonDisabled ? true : false}
        >
          Next
        </Button>
      </Row>
    </Section>
  );
};

export default SchoolDetailsScreen;
