import React from "react";

const PDFScreen = (props) => {
  const { formData } = props;

  return (
    <div style={{ fontSize: "12px" }}>
      <div className='page1'>
        <div
          style={{
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          <h3 style={{ fontSize: "24px" }}>
            KOTTAI MARIYAMMAN POLYTECHNIC COLLEGE
          </h3>
          <h6 style={{ fontSize: "16px" }}>
            (Run BY Kottai Mariyamman Educational Trust, Salem)
          </h6>
          <h6 style={{ fontSize: "16px" }}>
            Minnampalli (Po), Karipatti, (Via), SALEM-636 106
          </h6>
        </div>

        <div
          style={{
            textAlign: "center",

            position: "relative",
          }}
        >
          <h5 style={{ fontSize: "20px" }}>
            {`APPLICATION FORM FOR ADMISSION TO`}
            <br />
            {`
             ${
               formData.formType === "First_Year" ? "FIRST" : "SECOND"
             } YEAR DIPLOMA COURSE `}
            {new Date().getFullYear().toString()}-
            {new Date().getFullYear() + 1}
          </h5>
          <img
            src={`data:image/png;base64,${formData.applicantPhoto}`}
            alt='applicantPhoto'
            style={{
              width: "104px",
              height: "128px",
              objectFit: "cover",
              position: "absolute",
              right: "0",
              top: "-50px",
            }}
          />
        </div>
        <div style={{}}>
          <h5 style={{ fontSize: "20px" }}>Personal Details</h5>
          <div
            style={{
              display: "flex",
            }}
          >
            <div
              style={{
                flex: "0 0 50%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  wrap: "nowrap",
                  padding: "0 10px",
                  gap: "10px",
                }}
              >
                <div>Applicant Name:</div>
                <div>{formData?.applicantName}</div>
              </div>
              <div
                style={{
                  display: "flex",
                  wrap: "nowrap",
                  padding: "0 10px",
                  gap: "10px",
                }}
              >
                <div>Parent Name:</div>
                <div>{formData?.parentName}</div>
              </div>
              <div
                style={{
                  display: "flex",
                  wrap: "nowrap",
                  padding: "0 10px",
                  gap: "10px",
                }}
              >
                <div>Course Applied:</div>
                <div>{formData?.course}</div>
              </div>
              <div
                style={{
                  display: "flex",
                  wrap: "nowrap",
                  padding: "0 10px",
                  gap: "10px",
                }}
              >
                <div>Gender:</div>
                <div>{formData?.gender}</div>
              </div>
              <div
                style={{
                  display: "flex",
                  wrap: "nowrap",
                  padding: "0 10px",
                  gap: "10px",
                }}
              >
                <div>Aadhar Number:</div>
                <div>{formData?.aadharNumber}</div>
              </div>
              <div
                style={{
                  display: "flex",
                  wrap: "nowrap",
                  padding: "0 10px",
                  gap: "10px",
                }}
              >
                <div>Mobile Number:</div>
                <div>{formData?.mobile}</div>
              </div>
              <div
                style={{
                  display: "flex",
                  wrap: "nowrap",
                  padding: "0 10px",
                  gap: "10px",
                }}
              >
                <div>Mother Tongue:</div>
                <div>{formData?.motherTongue}</div>
              </div>
            </div>
            <div
              style={{
                flex: "0 0 50%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  wrap: "nowrap",
                  padding: "0 10px",
                  gap: "10px",
                }}
              >
                <div>DOB:</div>
                <div>{formData?.dob}</div>
              </div>
              <div
                style={{
                  display: "flex",
                  wrap: "nowrap",
                  padding: "0 10px",
                  gap: "10px",
                }}
              >
                <div>Place Of Birth:</div>
                <div>{formData?.placeOfBirth}</div>
              </div>
              <div
                style={{
                  display: "flex",
                  wrap: "nowrap",
                  padding: "0 10px",
                  gap: "10px",
                }}
              >
                <div>Nationality:</div>
                <div>{formData?.nationality}</div>
              </div>
              <div
                style={{
                  display: "flex",
                  wrap: "nowrap",
                  padding: "0 10px",
                  gap: "10px",
                }}
              >
                <div>Native District:</div>
                <div>{formData?.nativeDistrict}</div>
              </div>
              <div
                style={{
                  display: "flex",
                  wrap: "nowrap",
                  padding: "0 10px",
                  gap: "10px",
                }}
              >
                <div>Name of the Community:</div>
                <div>{formData?.community}</div>
              </div>
              <div
                style={{
                  display: "flex",
                  wrap: "nowrap",
                  padding: "0 10px",
                  gap: "10px",
                }}
              >
                <div>Caste:</div>
                <div>{formData?.caste}</div>
              </div>
            </div>
          </div>
        </div>

        <div style={{}}>
          <h5 style={{ fontSize: "20px" }}>Address Details</h5>
          <div
            style={{
              display: "flex",
            }}
          >
            <div
              style={{
                flex: "0 0 50%",
              }}
            >
              <strong>Communication Address</strong>
              <div style={{ padding: "0 10px" }}>
                {formData?.communicationAddress?.address1},
                <br />
                {formData?.communicationAddress?.address2},
                <br />
                {formData?.communicationAddress?.city},
                <br />
                {formData?.communicationAddress?.district},
                <br />
                {formData?.communicationAddress?.state},
                <br />
                {formData?.communicationAddress?.pincode},
                <br />
              </div>
            </div>
            <div
              style={{
                flex: "0 0 50%",
              }}
            >
              <strong>Permenant Address</strong>
              <div style={{ padding: "0 10px" }}>
                {formData?.sameAsCommunicationAddress
                  ? formData?.communicationAddress?.address1
                  : formData?.permenantAddress?.address1}
                ,
                <br />
                {formData?.sameAsCommunicationAddress
                  ? formData?.communicationAddress?.address2
                  : formData?.permenantAddress?.address2}
                ,
                <br />
                {formData?.sameAsCommunicationAddress
                  ? formData?.communicationAddress?.city
                  : formData?.permenantAddress?.city}
                ,
                <br />
                {formData?.sameAsCommunicationAddress
                  ? formData?.communicationAddress?.district
                  : formData?.permenantAddress?.district}
                ,
                <br />
                {formData?.sameAsCommunicationAddress
                  ? formData?.communicationAddress?.state
                  : formData?.permenantAddress?.state}
                , -
                {formData?.sameAsCommunicationAddress
                  ? formData?.communicationAddress?.pincode
                  : formData?.permenantAddress?.pincode}
              </div>
            </div>
          </div>
        </div>
        <div style={{}}>
          <h5 style={{ fontSize: "20px" }}>School Details</h5>
          <div
            style={{
              display: "flex",
            }}
          >
            <div
              style={{
                flex: "0 0 50%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  wrap: "nowrap",
                  padding: "0 10px",
                  gap: "10px",
                }}
              >
                <div>
                  {" "}
                  {formData.formType === "First_Year"
                    ? "SSLC Registration Number:"
                    : "HSC Registration Number:"}
                </div>
                <div>{formData?.SSLCRegistrationNumber}</div>
              </div>
              <div
                style={{
                  display: "flex",
                  wrap: "nowrap",
                  padding: "0 10px",
                  gap: "10px",
                }}
              >
                <div>SI.of the Certificate:</div>
                <div>{formData?.SIofCertficate}</div>
              </div>
              <div
                style={{
                  display: "flex",
                  wrap: "nowrap",
                  padding: "0 10px",
                  gap: "10px",
                }}
              >
                <div>Examination Passed:</div>
                <div>{formData?.examCodeNo}</div>
              </div>
            </div>
            <div
              style={{
                flex: "0 0 50%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  wrap: "nowrap",
                  padding: "0 10px",
                  gap: "10px",
                }}
              >
                <div>Designation of the Officer:</div>
                <div>{formData?.designOfOfficer}</div>
              </div>
              <div
                style={{
                  display: "flex",
                  wrap: "nowrap",
                  padding: "0 10px",
                  gap: "10px",
                }}
              >
                <div>Year of Passing:</div>
                <div>{formData?.yearOfPassing}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='page2'>
        <div
          style={{
            textAlign: "center",
            marginBottom: "10px",
          }}
        >
          <h3 style={{ fontSize: "24px" }}>
            KOTTAI MARIYAMMAN POLYTECHNIC COLLEGE
          </h3>
          <h6 style={{ fontSize: "16px" }}>
            (Run BY Kottai Mariyamman Educational Trust, Salem)
          </h6>
          <h6 style={{ fontSize: "16px" }}>
            Minnampalli (Po), Karipatti, (Via), SALEM-636 106
          </h6>
        </div>

        <div
          style={{
            textAlign: "center",

            position: "relative",
          }}
        >
          <h5 style={{ fontSize: "20px" }}>
            {`APPLICATION FORM FOR ADMISSION TO`}
            <br />
            {`
            ${
              formData.formType === "First_Year" ? "FIRST" : "SECOND"
            } YEAR DIPLOMA COURSE `}
            {new Date().getFullYear().toString()}-
            {new Date().getFullYear() + 1}
          </h5>
        </div>
        <div style={{}}>
          <h5 style={{ fontSize: "20px" }}>Academic Details</h5>
          <h6 style={{ fontSize: "16px" }}>
            {formData.formType === "First_Year"
              ? "Marks obtained in SSLC / Equivalent Examination"
              : "Marks obtained in HSC / Equivalent Examination"}
          </h6>
          {formData.examCodeNo === "HSC Vocational" && (
            <div>
              <div>Name of the Vocational Course:</div>
              <div>{formData.vocationalName}</div>
            </div>
          )}

          <div
            style={{
              marginBottom: "10px",
            }}
          >
            {formData.examCodeNo === "HSC Vocational" ? (
              <table>
                <thead>
                  <tr>
                    <th rowSpan='2'>SI.No</th>
                    <th rowSpan='2'>Subject</th>
                    <th colSpan='2'>Marks</th>
                    <th rowSpan='2'>Month and Year of Passing</th>
                  </tr>
                  <tr>
                    <th>Scored</th>
                    <th>Maximum</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>(i)</td>
                    <td>Tamil or any other language</td>
                    <td>{formData.primaryLanguage}</td>
                    <td>{formData.maximumMark}</td>
                    <td>{formData.primaryMonthandYearOfPassing}</td>
                  </tr>
                  <tr>
                    <td>(ii)</td>
                    <td>English</td>
                    <td>{formData.english}</td>
                    <td>{formData.maximumMark}</td>
                    <td>{formData.englishMonthandYearOfPassing}</td>
                  </tr>
                  <tr>
                    <td>(iii)</td>
                    <td>Mathematics (Or) Physics (Or) Chemistry</td>
                    <td>{formData.mathematics}</td>
                    <td>{formData.maximumMark}</td>
                    <td>
                      {formData.mathematicsMonthandYearOfPassing}
                    </td>
                  </tr>
                  <tr>
                    <td>(iv)</td>
                    <td>Theory</td>
                    <td>{formData.theory}</td>
                    <td>{formData.maximumMark}</td>
                    <td>{formData.theoryMonthandYearOfPassing}</td>
                  </tr>
                  <tr>
                    <td>(v)</td>
                    <td>Practical 1</td>
                    <td>{formData.practical1}</td>
                    <td>{formData.maximumMark}</td>
                    <td>
                      {formData.practical1MonthandYearOfPassing}
                    </td>
                  </tr>

                  <tr>
                    <td>(vi)</td>
                    <td>Practical 2</td>
                    <td>{formData.practical2}</td>
                    <td>{formData.maximumMark}</td>
                    <td>
                      {formData.practical2MonthandYearOfPassing}
                    </td>
                  </tr>

                  <tr>
                    <td></td>
                    <td>TOTAL</td>
                    <td>{formData.total}</td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <table>
                <thead>
                  <tr>
                    <th rowSpan='2'>SI.No</th>
                    <th rowSpan='2'>Subject</th>
                    <th colSpan='2'>Marks</th>
                    <th rowSpan='2'>Month and Year of Passing</th>
                  </tr>
                  <tr>
                    <th>Scored</th>
                    <th>Maximum</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>(i)</td>
                    <td>Tamil or any other language</td>
                    <td>{formData?.primaryLanguage}</td>
                    <td>{formData?.maximumMark}</td>
                    <td>{formData?.primaryMonthandYearOfPassing}</td>
                  </tr>
                  <tr>
                    <td>(ii)</td>
                    <td>English</td>
                    <td>{formData?.english}</td>
                    <td>{formData?.maximumMark}</td>
                    <td>{formData?.englishMonthandYearOfPassing}</td>
                  </tr>
                  <tr>
                    <td>(iii)</td>
                    <td>Mathematics</td>
                    <td>{formData?.mathematics}</td>
                    <td>{formData?.maximumMark}</td>
                    <td>
                      {formData?.mathematicsMonthandYearOfPassing}
                    </td>
                  </tr>
                  <tr>
                    <td>(iv)</td>
                    <td>
                      {formData.formType === "First_Year"
                        ? "Science"
                        : "Physics"}
                    </td>
                    <td>{formData?.science}</td>
                    <td>{formData?.maximumMark}</td>
                    <td>{formData?.scienceMonthandYearOfPassing}</td>
                  </tr>
                  <tr>
                    <td>(v)</td>
                    <td>
                      {formData.formType === "First_Year"
                        ? "Social Science"
                        : "Chemistry"}
                    </td>
                    <td>{formData?.socialScience}</td>
                    <td>{formData?.maximumMark}</td>
                    <td>
                      {formData?.socialScienceMonthandYearOfPassing}
                    </td>
                  </tr>

                  {formData?.electiveAvailable && (
                    <tr>
                      <td>(vi)</td>
                      <td>Elective (if any)</td>
                      <td>{formData?.elective}</td>
                      <td>{formData?.maximumMark}</td>
                      <td>
                        {formData?.electiveMonthandYearOfPassing}
                      </td>
                    </tr>
                  )}

                  <tr>
                    <td></td>
                    <td>TOTAL</td>
                    <td>{formData?.total}</td>
                    <td>
                      {formData.electiveAvailable ||
                      formData.examCodeNo === "HSC Vocational"
                        ? "600"
                        : "500"}
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
        </div>
        <div style={{}}>
          <h6 style={{ fontSize: "16px" }}>
            Details of studies during last 2 years
          </h6>
          <table>
            <thead>
              <tr>
                <th>SI.No</th>
                <th>Class</th>
                <th>Month and Year of Passing</th>
                <th>
                  {" "}
                  {formData.formType === "First_Year"
                    ? "SSLC / Matriculation / I.S.C Reg. No."
                    : "HSC Academic / HSC Vocational Reg. No"}
                </th>
                <th>Name and Address of the School</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1.</td>
                <td>
                  {" "}
                  {formData.formType === "First_Year"
                    ? "IX Std"
                    : "XI Std"}
                </td>
                <td>{formData?.ninthMonthandYearOfPassing}</td>
                <td>{formData?.ninthReg_No}</td>
                <td>{formData?.ninthNameAndAddressOftheSchool}</td>
              </tr>
              <tr>
                <td>2.</td>
                <td>
                  {" "}
                  {formData.formType === "First_Year"
                    ? "X Std"
                    : "XII Std"}
                </td>
                <td>{formData?.tenthMonthandYearOfPassing}</td>
                <td>{formData?.tenthReg_No}</td>
                <td>{formData?.tenthNameAndAddressOftheSchool}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className='page3'>
        <img
          src={`data:image/png;base64,${formData.tenthMarkSheet}`}
          alt='Marksheet'
          width='100%'
        />
      </div>
      <div className='page3'>
        <img
          src={`data:image/png;base64,${formData.tenthTc}`}
          alt='Tc'
          width='100%'
        />
      </div>
    </div>
  );
};

export default PDFScreen;
