import React, { useState } from "react";
import { createContext } from "react";
export const formDataContext = createContext();
const FormDataProvider = ({ children }) => {
  const defaultData = {
    formType: "",
    applicantName: "",
    applicantPhoto: "",
    applicantPhotoImg: [],
    aadharNumber: "",
    mobile: "",
    parentName: "",
    course: "",
    gender: "Male",
    community: "",
    caste: "",
    dob: "",
    dobCalendar: false,
    placeOfBirth: "",
    nativeDistrict: "",
    motherTongue: "Tamil",
    nationality: "Indian",
    communicationAddress: {
      address1: "",
      address2: "",
      city: "",
      district: "",
      state: "",
      pincode: "",
    },
    sameAsCommunicationAddress: false,
    permenantAddress: {
      address1: "",
      address2: "",
      city: "",
      district: "",
      state: "",
      pincode: "",
    },
    SSLCRegistrationNumber: "",
    SIofCertficate: "",
    designOfOfficer: "",
    examCodeNo: "",
    yearOfPassing: "",
    yearOfPassingCalendar: false,
    primaryLanguage: "",
    english: "",
    mathematics: "",
    science: "",
    socialScience: "",
    theory: "",
    practical1: "",
    practical2: "",
    elective: "",
    total: "",
    ninthStdMonthandYearOfPassing: "",
    ninthStdMonthandYearOfPassingCalendar: false,
    ninthReg_No: "",
    tenthReg_No: "",
    maximumMark: 100,
    ninthNameAndAddressOftheSchool: "",
    tenthStdMonthandYearOfPassing: "",
    tenthNameAndAddressOftheSchool: "",
    tenthStdMonthandYearOfPassingCalendar: false,
    primaryMonthandYearOfPassing: "",
    primaryMonthandYearOfPassingCalendar: false,
    englishMonthandYearOfPassing: "",
    englishMonthandYearOfPassingCalendar: false,
    mathematicsMonthandYearOfPassing: "",
    mathematicsMonthandYearOfPassingCalendar: false,
    scienceMonthandYearOfPassing: "",
    scienceMonthandYearOfPassingCalendar: false,
    socialScienceMonthandYearOfPassing: "",
    socialScienceMonthandYearOfPassingCalendar: false,
    electiveMonthandYearOfPassing: "",
    electiveMonthandYearOfPassingCalendar: false,
    electiveAvailable: false,
    theoryMonthandYearOfPassing: "",
    theoryeMonthandYearOfPassingCalendar: false,
    vocationalName: "",
    practical1MonthandYearOfPassing: "",
    practical1MonthandYearOfPassingCalendar: false,
    practical2MonthandYearOfPassing: "",
    practical2MonthandYearOfPassingCalendar: false,
    tenthMarkSheet: "",
    tenthMarkSheetImg: [],
    tenthTc: "",
    tenthTcImg: [],
  };

  const [formData, setFormData] = useState(defaultData);

  return (
    <formDataContext.Provider value={{ formData, setFormData }}>
      {children}
    </formDataContext.Provider>
  );
};

export default FormDataProvider;
