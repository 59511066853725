import './styles/main.css';
import Header from './components/Header';
import Footer from './components/Footer';
import HomeScreen from './screens/HomeScreen';
import { Route, Switch } from 'react-router-dom';
import AboutScreen from './screens/AboutScreen';
import CoursesScreen from './screens/CoursesScreen';
import ContactUsScreen from './screens/ContactUsScreen';
import GalleryScreen from './screens/GalleryScreen';
import ApplicationScreen from './screens/ApplicationScreen';
import FacilitiesScreen from './screens/FacilitiesScreen';
import PressReleaseScreen from './screens/PressReleaseScreen';
import { useEffect, useState } from 'react';
import LoadingScreen from './screens/LoadingScreen';
import ErrorScreen from './screens/ErrorScreen';
import PDFScreen from './screens/PDFScreen';
import ImportantLinks from './screens/ImportantLinks';
import FeedbackScreen from './screens/FeedbackScreen';

function App() {
  const [loading, setLoading] = useState(true);
  const handleLoading = () => {
    setLoading(false);
  };
  useEffect(() => {
    window.addEventListener('load', handleLoading);
    return () => window.removeEventListener('load', handleLoading);
  }, []);
  return loading ? (
    <LoadingScreen />
  ) : (
    <>
      <Header />
      <Switch>
        <Route path='/pdf' exact component={PDFScreen} />
        <Route
          path='/type/:type'
          exact
          component={ApplicationScreen}
        />
        <Route
          path='/press-realease'
          exact
          component={PressReleaseScreen}
        />
        <Route path='/gallery' exact component={GalleryScreen} />
        <Route
          path='/facilities'
          exact
          component={FacilitiesScreen}
        />
        <Route
          path='/important-links'
          exact
          component={ImportantLinks}
        />
        <Route path='/feedback' exact component={FeedbackScreen} />
        <Route path='/contact-us' exact component={ContactUsScreen} />
        <Route path='/courses' exact component={CoursesScreen} />
        <Route path='/about' exact component={AboutScreen} />
        <Route
          path='/dazzexports'
          exact
          render={() =>
            (window.location.href = 'https://www.dazzexports.com')
          }
        />
        <Route path='/' exact component={HomeScreen} />
        <Route component={ErrorScreen} />
      </Switch>
      <Footer />
    </>
  );
}

export default App;
