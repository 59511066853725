import React, { useContext, useEffect, useState } from "react";
import Stepper from "react-stepper-horizontal/lib/Stepper";
import Button from "../components/Button";
import Col from "../components/Col";
import CheckBox from "../components/forms/CheckBox";
import ItemForm from "../components/forms/ItemForm";
import Row from "../components/Row";
import Section from "../components/Section";
import Typography from "../components/Typography";
import { formDataContext } from "../context/FormDataProvider";

const AddressScreen = ({ previous, next }) => {
  const { formData, setFormData } = useContext(formDataContext);
  const [windowWidth, setWindowWidth] = useState();
  const buttonDisabled = formData.sameAsCommunicationAddress
    ? !(
        formData.communicationAddress.address1 &&
        formData.communicationAddress.address2 &&
        formData.communicationAddress.city &&
        formData.communicationAddress.district &&
        formData.communicationAddress.state &&
        formData.communicationAddress.pincode
      )
    : !(
        formData.communicationAddress.address1 &&
        formData.communicationAddress.address2 &&
        formData.communicationAddress.city &&
        formData.communicationAddress.district &&
        formData.communicationAddress.state &&
        formData.communicationAddress.pincode &&
        formData.permenantAddress.address1 &&
        formData.permenantAddress.address2 &&
        formData.permenantAddress.city &&
        formData.permenantAddress.district &&
        formData.permenantAddress.state &&
        formData.permenantAddress.pincode
      );
  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };
  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () =>
      window.removeEventListener("resize", updateDimensions);
  }, []);
  const steps =
    windowWidth > 600
      ? [
          { title: "Personal Details" },
          { title: "Address Details" },
          { title: "School Details" },
          { title: "Academic Details" },
          { title: "Upload Files" },
          { title: "Confirmation" },
        ]
      : [
          { title: "" },
          { title: "" },
          { title: "" },
          { title: "" },
          { title: "" },
          { title: "" },
        ];

  return (
    <Section>
      <div style={{ marginBottom: "50px" }}>
        <Stepper
          steps={steps}
          activeStep={1}
          activeColor='#5bb462'
          completeColor='#5bb462'
          completeTitleColor='#5bb462'
          activeTitleColor='#5bb462'
          titleFontSize={14}
        />
      </div>
      <Row v_align='center' h_align='space-between'>
        <Typography variant='h4'>Address Details</Typography>

        <CheckBox
          label='Same As Communication Address'
          name='sameAsCommunicationAddress'
          value={formData.sameAsCommunicationAddress}
          checked={formData.sameAsCommunicationAddress}
          onChange={(e) =>
            setFormData({
              ...formData,
              sameAsCommunicationAddress: e.target.checked,
            })
          }
        />
      </Row>

      <Row>
        <Col span='2'>
          <Typography variant='h6'>Communication Address</Typography>
          <div className='form'>
            <ItemForm
              label='Address1'
              name='communicationAddress.address1'
              value={formData.communicationAddress.address1}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  communicationAddress: {
                    ...prev.communicationAddress,
                    address1: e.target.value,
                  },
                }))
              }
              required
            />
            <ItemForm
              label='Address2'
              name='communicationAddress.address2'
              value={formData.communicationAddress.address2}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  communicationAddress: {
                    ...prev.communicationAddress,
                    address2: e.target.value,
                  },
                }))
              }
              required
            />
            <ItemForm
              label='City'
              name='communicationAddress.city'
              value={formData.communicationAddress.city}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  communicationAddress: {
                    ...prev.communicationAddress,
                    city: e.target.value,
                  },
                }))
              }
              required
            />
            <ItemForm
              label='District'
              name='communicationAddress.district'
              value={formData.communicationAddress.district}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  communicationAddress: {
                    ...prev.communicationAddress,
                    district: e.target.value,
                  },
                }))
              }
              required
            />
            <ItemForm
              label='State'
              name='communicationAddress.state'
              value={formData.communicationAddress.state}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  communicationAddress: {
                    ...prev.communicationAddress,
                    state: e.target.value,
                  },
                }))
              }
              required
            />
            <ItemForm
              label='Pincode'
              name='communicationAddress.pincode'
              value={formData.communicationAddress.pincode}
              maxLength='6'
              onChange={(e) => {
                const regExp = /^[0-9\b]+$/;
                if (
                  e.target.value === "" ||
                  regExp.test(e.target.value)
                ) {
                  setFormData((prev) => ({
                    ...prev,
                    communicationAddress: {
                      ...prev.communicationAddress,
                      pincode: e.target.value,
                    },
                  }));
                }
              }}
              onBlur={(e) => {
                if (e.target.value.length < 6) {
                  e.target.style.border = "1px solid red";
                  e.target.parentElement.nextElementSibling.style.visibility =
                    "visible";
                } else {
                  e.target.style.border = "1px solid #d1d9d9";
                  e.target.parentElement.nextElementSibling.style.visibility =
                    "hidden";
                }
              }}
              required
            />
          </div>
        </Col>
        <Col span='2'>
          <Typography variant='h6'>Permenant Address</Typography>
          <div className='form'>
            <ItemForm
              label='Address1'
              name='permenantAddress.address1'
              value={`${
                formData.sameAsCommunicationAddress
                  ? formData.communicationAddress.address1
                  : formData.permenantAddress.address1
              }`}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  permenantAddress: {
                    ...prev.permenantAddress,
                    address1: e.target.value,
                  },
                }))
              }
              required
            />
            <ItemForm
              label='Address2'
              name='permenantAddress.address2'
              value={`${
                formData.sameAsCommunicationAddress
                  ? formData.communicationAddress.address2
                  : formData.permenantAddress.address2
              }`}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  permenantAddress: {
                    ...prev.permenantAddress,
                    address2: e.target.value,
                  },
                }))
              }
              required
            />
            <ItemForm
              label='City'
              name='permenantAddress.city'
              value={`${
                formData.sameAsCommunicationAddress
                  ? formData.communicationAddress.city
                  : formData.permenantAddress.city
              }`}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  permenantAddress: {
                    ...prev.permenantAddress,
                    city: e.target.value,
                  },
                }))
              }
              required
            />
            <ItemForm
              label='District'
              name='permenantAddress.district'
              value={`${
                formData.sameAsCommunicationAddress
                  ? formData.communicationAddress.district
                  : formData.permenantAddress.district
              }`}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  permenantAddress: {
                    ...prev.permenantAddress,
                    district: e.target.value,
                  },
                }))
              }
              required
            />
            <ItemForm
              label='State'
              name='permenantAddress.state'
              value={`${
                formData.sameAsCommunicationAddress
                  ? formData.communicationAddress.state
                  : formData.permenantAddress.state
              }`}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  permenantAddress: {
                    ...prev.permenantAddress,
                    state: e.target.value,
                  },
                }))
              }
              required
            />
            <ItemForm
              label='Pincode'
              name='permenantAddress.pincode'
              variant='pincode'
              value={`${
                formData.sameAsCommunicationAddress
                  ? formData.communicationAddress.pincode
                  : formData.permenantAddress.pincode
              }`}
              onChange={(e) => {
                const regExp = /^[0-9\b]+$/;
                if (
                  e.target.value === "" ||
                  regExp.test(e.target.value)
                ) {
                  setFormData((prev) => ({
                    ...prev,
                    permenantAddress: {
                      ...prev.permenantAddress,
                      pincode: e.target.value,
                    },
                  }));
                }
              }}
              onBlur={(e) => {
                if (e.target.value.length < 6) {
                  e.target.style.border = "1px solid red";
                  e.target.parentElement.nextElementSibling.style.visibility =
                    "visible";
                } else {
                  e.target.style.border = "1px solid #d1d9d9";
                  e.target.parentElement.nextElementSibling.style.visibility =
                    "hidden";
                }
              }}
              maxLength='6'
              required
            />
          </div>
        </Col>
      </Row>
      <Row h_align='flex-end' gap='2'>
        <Button variant='primary' onClick={previous}>
          Previous
        </Button>
        <Button
          variant='primary'
          onClick={next}
          disabled={buttonDisabled ? true : false}
        >
          Next
        </Button>
      </Row>
    </Section>
  );
};

export default AddressScreen;
