import React, { useRef, useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { X } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import logo from '../images/Kmptc.png';
import Button from './Button';

const Brand = (props) => {
  return <>{props.children}</>;
};

const NavToggle = ({ onClick }) => {
  return (
    <div id='hamburger-icon' title='Menu' onClick={onClick}>
      <span className='line line-1'></span>
      <span className='line line-2'></span>
      <span className='line line-3'></span>
    </div>
  );
};

const NavMenu = (props) => {
  return <>{props.children}</>;
};

const NavBar = () => {
  const menuContainerRef = useRef();
  const history = useHistory();
  const slideOpenHandler = () => {
    menuContainerRef.current.classList.add('open');
    window.document.body.classList.add('of-hidden');
  };
  const slideCloseHandler = () => {
    menuContainerRef.current.classList.remove('open');
    window.document.body.classList.remove('of-hidden');
  };
  useEffect(() => {
    history.listen((location) => {
      menuContainerRef.current.classList.remove('open');
      window.document.body.classList.remove('of-hidden');
    });
  }, [history]);
  return (
    <nav className='nav'>
      <Brand>
        <Link to='/' className='logo'>
          <img src={logo} alt='KMPTC' width='90' height='90' />
        </Link>
      </Brand>
      <NavToggle onClick={slideOpenHandler}></NavToggle>
      <div className='nav__menu-container' ref={menuContainerRef}>
        <X
          size='24'
          className='nav__close-icon'
          onClick={slideCloseHandler}
        ></X>
        <NavMenu>
          <ul className='nav__menu'>
            <li>
              <NavLink
                exact
                to='/'
                className='nav__item'
                activeClassName='active'
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/about'
                className='nav__item'
                activeClassName='active'
              >
                About Us
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/courses'
                className='nav__item'
                activeClassName='active'
              >
                Courses
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/facilities'
                className='nav__item'
                activeClassName='active'
              >
                Facilities
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/gallery'
                className='nav__item'
                activeClassName='active'
              >
                Gallery
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/press-realease'
                className='nav__item'
                activeClassName='active'
              >
                Press Release
              </NavLink>
            </li>
            <li className='nav-item dropdown'>
              <a
                id='navbarDropdownMenuLink'
                role='button'
                data-bs-toggle='dropdown'
                aria-expanded='false'
                className='nav-link dropdown-toggle'
              >
                Important Links
              </a>
              <ul
                className='dropdown-menu'
                aria-labelledby='navbarDropdownMenuLink'
              >
                <li>
                  <NavLink
                    className='dropdown-item'
                    to='/important-links'
                  >
                    Important Links{' '}
                  </NavLink>
                </li>
                <li>
                  <NavLink className='dropdown-item' to='/feedback'>
                    Feedback
                  </NavLink>
                </li>
              </ul>
              {/* <div className='nav__item nav__dropdown'>
                <div className='nav__dropdown-text'>
                  Important Links
                </div>
                <ul className='nav__dropdown-list'>
                  <li>
                    <NavLink
                      to='/important-links'
                      className='nav__item'
                      activeClassName='active'
                    >
                      Important Links
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to='/feedback'
                      className='nav__item'
                      activeClassName='active'
                    >
                      Feedback
                    </NavLink>
                  </li>
                </ul>
              </div> */}
            </li>
            <li>
              <NavLink
                to='/contact-us'
                className='nav__item'
                activeClassName='active'
              >
                Contact Us
              </NavLink>
            </li>
          </ul>
        </NavMenu>
        <NavMenu>
          <ul className='nav__menu'>
            <Button
              variant='text'
              className='typography--color-primary'
            >
              Login
            </Button>
            <Button variant='primary'>Register</Button>
          </ul>
        </NavMenu>
      </div>
    </nav>
  );
};

export default NavBar;
