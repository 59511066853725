import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PencilFill, X } from "react-bootstrap-icons";
import Stepper from "react-stepper-horizontal/lib/Stepper";
import { SRLWrapper } from "simple-react-lightbox";
import Button from "../components/Button";
import Col from "../components/Col";
import Row from "../components/Row";
import Section from "../components/Section";
import Typography from "../components/Typography";
import { formDataContext } from "../context/FormDataProvider";
import { registerApplication } from "../actions/taskActions";
import PDFScreen from "./PDFScreen";
import ReactDOMServer from "react-dom/server";
import { SEND_APPLICATION_RESET } from "../constants/taskConstants";
import Spinner from "../components/Spinner";
import MessageBox from "../components/MessageBox";
import Modal from "../components/Modal";
import Card, {
  CardActions,
  CardContent,
  CardHeader,
} from "../components/Card";

import { useHistory } from "react-router";
const ConfirmationScreen = ({ previous, next }) => {
  const { formData } = useContext(formDataContext);
  const [modalStatus, setModalStatus] = useState(false);
  const [windowWidth, setWindowWidth] = useState();
  const history = useHistory();
  const { loading, success, error } = useSelector(
    (state) => state.registerApplication
  );
  const dispatch = useDispatch();

  const submitHandler = async () => {
    const html = ReactDOMServer.renderToStaticMarkup(
      <PDFScreen formData={formData} />
    ).toString();

    dispatch(registerApplication(html));
  };
  useEffect(() => {
    if (success) {
      setModalStatus(true);
    }
    if (error) {
      const timer = setTimeout(() => {
        dispatch({ type: SEND_APPLICATION_RESET });
      }, 5000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [success, error, dispatch]);
  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };
  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () =>
      window.removeEventListener("resize", updateDimensions);
  }, []);

  const steps =
    windowWidth > 600
      ? [
          { title: "Personal Details" },
          { title: "Address Details" },
          { title: "School Details" },
          { title: "Academic Details" },
          { title: "Upload Files" },
          { title: "Confirmation" },
        ]
      : [
          { title: "" },
          { title: "" },
          { title: "" },
          { title: "" },
          { title: "" },
          { title: "" },
        ];

  return (
    <>
      {modalStatus && (
        <Modal>
          <Card padding='2' boxShadow='0' border='0'>
            <CardHeader justify='center'>Success</CardHeader>
            <CardContent justify='center'>
              <Typography>
                Application is submitted successfully. We will contact
                you soon.
              </Typography>
            </CardContent>
            <CardActions
              justify='center'
              padding='1rem 0 0 0'
              gap='2rem'
            >
              <Button
                onClick={() => {
                  setModalStatus(false);
                  dispatch({ type: SEND_APPLICATION_RESET });
                  history.push(`/`);
                }}
                variant='primary'
              >
                Ok
              </Button>
            </CardActions>
          </Card>
        </Modal>
      )}
      <Section>
        <div
          style={{ marginBottom: "50px" }}
          className='hide_on_print'
          data-html2canvas-ignore='true'
        >
          <Stepper
            steps={steps}
            activeStep={4}
            activeColor='#5bb462'
            completeColor='#5bb462'
            completeTitleColor='#5bb462'
            activeTitleColor='#5bb462'
            titleFontSize={14}
          />
        </div>

        <Row h_align='center' v_align='center' gap='2'>
          <Typography variant='h2'>
            {`Application Form For Admission To ${
              formData.formType === "First_Year" ? "First" : "Second"
            } Year Diploma
              Course`}
          </Typography>
        </Row>
        <Row h_align='space-between' v_align='center'>
          <Row wrap='nowrap'>
            Date:{" "}
            {`${new Date().getDate()}-${
              new Date().getMonth() + 1
            }-${new Date().getFullYear()}`}
          </Row>
          <div>
            <img
              src={`data:image/png;base64,${formData.applicantPhoto}`}
              alt='applicantPhoto'
              style={{
                width: "10.4rem",
                height: "12.8rem",
                objectFit: "cover",
              }}
            />
          </div>
        </Row>
        <div style={{ marginBottom: "10px" }}>
          <Row v_align='center' gap='2'>
            <Typography variant='h4'>Personal Details</Typography>
            <Button>
              <PencilFill
                size='18'
                color='#5bb462'
                data-html2canvas-ignore='true'
              />
            </Button>
          </Row>

          <Row>
            <Col span='2'>
              <Row gap='2' padding='1rem 0'>
                <div>Applicant Name:</div>
                <div>{formData.applicantName}</div>
              </Row>
              <Row gap='2' padding='1rem 0'>
                <div>Parent Name:</div>
                <div>{formData.parentName}</div>
              </Row>
              <Row gap='2' padding='1rem 0'>
                <div>Course Applied:</div>
                <div>{formData.course}</div>
              </Row>
              <Row gap='2' padding='1rem 0'>
                <div>Gender:</div>
                <div>{formData.gender}</div>
              </Row>
              <Row gap='2' padding='1rem 0'>
                <div>Aadhar Number:</div>
                <div>{formData.aadharNumber}</div>
              </Row>
              <Row gap='2' padding='1rem 0'>
                <div>Mobile Number:</div>
                <div>{formData.mobile}</div>
              </Row>
              <Row gap='2' padding='1rem 0'>
                <div>Mother Tongue:</div>
                <div>{formData.motherTongue}</div>
              </Row>
            </Col>
            <Col span='2'>
              <Row gap='2' padding='1rem 0'>
                <div>DOB:</div>
                <div>{formData.dob}</div>
              </Row>
              <Row gap='2' padding='1rem 0'>
                <div>Place Of Birth:</div>
                <div>{formData.placeOfBirth}</div>
              </Row>
              <Row gap='2' padding='1rem 0'>
                <div>Nationality:</div>
                <div>{formData.nationality}</div>
              </Row>
              <Row gap='2' padding='1rem 0'>
                <div>Native District:</div>
                <div>{formData.nativeDistrict}</div>
              </Row>
              <Row gap='2' padding='1rem 0'>
                <div>Name of the Community:</div>
                <div>{formData.community}</div>
              </Row>
              <Row gap='2' padding='1rem 0'>
                <div>Caste:</div>
                <div>{formData.caste}</div>
              </Row>
            </Col>
          </Row>
        </div>
        <div style={{ marginBottom: "10px" }}>
          <Row v_align='center' gap='2'>
            <Typography variant='h4'>Address Details</Typography>
            <Button>
              <PencilFill
                size='18'
                color='#5bb462'
                data-html2canvas-ignore='true'
              />
            </Button>
          </Row>
          <Row>
            <Col span='2'>
              <Typography variant='h6'>
                Communication Address
              </Typography>
              <div>
                {formData.communicationAddress.address1}
                <br />
                {formData.communicationAddress.address2}
                <br />
                {formData.communicationAddress.city}
                <br />
                {formData.communicationAddress.district}
                <br />
                {formData.communicationAddress.state} -{" "}
                {formData.communicationAddress.pincode}
                <br />
              </div>
            </Col>
            <Col span='2'>
              <Typography variant='h6'>Permenant Address</Typography>
              <div>
                {formData.sameAsCommunicationAddress
                  ? formData.communicationAddress.address1
                  : formData.permenantAddress.address1}
                <br />
                {formData.sameAsCommunicationAddress
                  ? formData.communicationAddress.address2
                  : formData.permenantAddress.address2}
                <br />
                {formData.sameAsCommunicationAddress
                  ? formData.communicationAddress.city
                  : formData.permenantAddress.city}
                <br />{" "}
                {formData.sameAsCommunicationAddress
                  ? formData.communicationAddress.district
                  : formData.permenantAddress.district}
                <br />{" "}
                {formData.sameAsCommunicationAddress
                  ? formData.communicationAddress.state
                  : formData.permenantAddress.state}
                -{" "}
                {formData.sameAsCommunicationAddress
                  ? formData.communicationAddress.pincode
                  : formData.permenantAddress.pincode}
              </div>
            </Col>
          </Row>
        </div>
        <div style={{ marginBottom: "10px" }}>
          <Row>
            <Typography variant='h4'>School Details</Typography>
            <Button>
              <PencilFill
                size='18'
                color='#5bb462'
                data-html2canvas-ignore='true'
              />
            </Button>
          </Row>
          <Row>
            <Col span='2'>
              <Row gap='2' padding='1rem 0'>
                <div>
                  {formData.formType === "First_Year"
                    ? "SSLC Registration Number:"
                    : "HSC Registration Number:"}
                </div>
                <div>{formData.SSLCRegistrationNumber}</div>
              </Row>
              <Row gap='2' padding='1rem 0'>
                <div>SI.of the Certificate:</div>
                <div>{formData.SIofCertficate}</div>
              </Row>
              <Row gap='2' padding='1rem 0'>
                <div>Examination Passed:</div>
                <div>{formData.examCodeNo}</div>
              </Row>
            </Col>
            <Col span='2'>
              <Row gap='2' padding='1rem 0'>
                <div>Designation of the Officer:</div>
                <div>{formData.designOfOfficer}</div>
              </Row>
              <Row gap='2' padding='1rem 0'>
                <div>Year of Passing:</div>
                <div>{formData.yearOfPassing}</div>
              </Row>
            </Col>
          </Row>
        </div>
        <div style={{ marginBottom: "10px" }}>
          <Row>
            <Typography variant='h4'>Academic Details</Typography>
            <Button>
              <PencilFill
                size='18'
                color='#5bb462'
                data-html2canvas-ignore='true'
              />
            </Button>
          </Row>
          <Typography variant='h6'>
            {formData.formType === "First_Year"
              ? "Marks obtained in SSLC / Equivalent Examination"
              : "Marks obtained in HSC / Equivalent Examination"}
          </Typography>
          {formData.examCodeNo === "HSC Vocational" && (
            <Row gap='2' padding='1rem 0'>
              <div>Name of the Vocational Course:</div>
              <div>{formData.vocationalName}</div>
            </Row>
          )}
          <div style={{ marginBottom: "2rem" }}>
            {formData.examCodeNo === "HSC Vocational" ? (
              <div style={{ overflowX: "auto" }}>
                <table>
                  <thead>
                    <tr>
                      <th rowSpan='2'>SI.No</th>
                      <th rowSpan='2'>Subject</th>
                      <th colSpan='2'>Marks</th>
                      <th rowSpan='2'>Month and Year of Passing</th>
                    </tr>
                    <tr>
                      <th>Scored</th>
                      <th>Maximum</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>(i)</td>
                      <td>Tamil or any other language</td>
                      <td>{formData.primaryLanguage}</td>
                      <td>{formData.maximumMark}</td>
                      <td>{formData.primaryMonthandYearOfPassing}</td>
                    </tr>
                    <tr>
                      <td>(ii)</td>
                      <td>English</td>
                      <td>{formData.english}</td>
                      <td>{formData.maximumMark}</td>
                      <td>{formData.englishMonthandYearOfPassing}</td>
                    </tr>
                    <tr>
                      <td>(iii)</td>
                      <td>Mathematics (Or) Physics (Or) Chemistry</td>
                      <td>{formData.mathematics}</td>
                      <td>{formData.maximumMark}</td>
                      <td>
                        {formData.mathematicsMonthandYearOfPassing}
                      </td>
                    </tr>
                    <tr>
                      <td>(iv)</td>
                      <td>Theory</td>
                      <td>{formData.theory}</td>
                      <td>{formData.maximumMark}</td>
                      <td>{formData.theoryMonthandYearOfPassing}</td>
                    </tr>
                    <tr>
                      <td>(v)</td>
                      <td>Practical 1</td>
                      <td>{formData.practical1}</td>
                      <td>{formData.maximumMark}</td>
                      <td>
                        {formData.practical1MonthandYearOfPassing}
                      </td>
                    </tr>

                    <tr>
                      <td>(vi)</td>
                      <td>Practical 2</td>
                      <td>{formData.practical2}</td>
                      <td>{formData.maximumMark}</td>
                      <td>
                        {formData.practical2MonthandYearOfPassing}
                      </td>
                    </tr>

                    <tr>
                      <td></td>
                      <td>TOTAL</td>
                      <td>{formData.total}</td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              <div style={{ overflowX: "auto" }}>
                <table>
                  <thead>
                    <tr>
                      <th rowSpan='2'>SI.No</th>
                      <th rowSpan='2'>Subject</th>
                      <th colSpan='2'>Marks</th>
                      <th rowSpan='2'>Month and Year of Passing</th>
                    </tr>
                    <tr>
                      <th>Scored</th>
                      <th>Maximum</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>(i)</td>
                      <td>Tamil or any other language</td>
                      <td>{formData.primaryLanguage}</td>
                      <td>{formData.maximumMark}</td>
                      <td>{formData.primaryMonthandYearOfPassing}</td>
                    </tr>
                    <tr>
                      <td>(ii)</td>
                      <td>English</td>
                      <td>{formData.english}</td>
                      <td>{formData.maximumMark}</td>
                      <td>{formData.englishMonthandYearOfPassing}</td>
                    </tr>
                    <tr>
                      <td>(iii)</td>
                      <td>Mathematics</td>
                      <td>{formData.mathematics}</td>
                      <td>{formData.maximumMark}</td>
                      <td>
                        {formData.mathematicsMonthandYearOfPassing}
                      </td>
                    </tr>
                    <tr>
                      <td>(iv)</td>
                      <td>
                        {formData.formType === "First_Year"
                          ? "Science"
                          : "Physics"}
                      </td>
                      <td>{formData.science}</td>
                      <td>{formData.maximumMark}</td>
                      <td>{formData.scienceMonthandYearOfPassing}</td>
                    </tr>
                    <tr>
                      <td>(v)</td>
                      <td>
                        {formData.formType === "First_Year"
                          ? "Social Science"
                          : "Chemistry"}
                      </td>
                      <td>{formData.socialScience}</td>
                      <td>{formData.maximumMark}</td>
                      <td>
                        {formData.socialScienceMonthandYearOfPassing}
                      </td>
                    </tr>
                    {formData.electiveAvailable && (
                      <tr>
                        <td>(vi)</td>
                        <td>Elective (if any)</td>
                        <td>{formData.elective}</td>
                        <td>{formData.maximumMark}</td>
                        <td>
                          {formData.electiveMonthandYearOfPassing}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td></td>
                      <td>TOTAL</td>
                      <td>{formData.total}</td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <div style={{ marginBottom: "2rem" }}>
            <Typography variant='h6'>
              Details of studies during last 2 years
            </Typography>
            <div style={{ overflowX: "auto" }}>
              <table>
                <thead>
                  <tr>
                    <th>SI.No</th>
                    <th>Class</th>
                    <th>Month and Year of Passing</th>
                    <th>
                      {formData.formType === "First_Year"
                        ? "SSLC / Matriculation / I.S.C Reg. No."
                        : "HSC Academic / HSC Vocational Reg. No"}
                    </th>
                    <th>Name and Address of the School</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1.</td>
                    <td>
                      {formData.formType === "First_Year"
                        ? "IX Std"
                        : "XI Std"}
                    </td>
                    <td>{formData.ninthMonthandYearOfPassing}</td>
                    <td>{formData.ninthReg_No}</td>
                    <td>{formData.ninthNameAndAddressOftheSchool}</td>
                  </tr>
                  <tr>
                    <td>2.</td>
                    <td>
                      {formData.formType === "First_Year"
                        ? "X Std"
                        : "XII Std"}
                    </td>
                    <td>{formData.tenthMonthandYearOfPassing}</td>
                    <td>{formData.tenthReg_No}</td>
                    <td>{formData.tenthNameAndAddressOftheSchool}</td>
                  </tr>
                </tbody>
              </table>
            </div>{" "}
          </div>
          <div
            className='hide_on_print'
            data-html2canvas-ignore='true'
          >
            <Typography variant='h6'>Uploaded Documents</Typography>
            <Row gap='2'>
              <SRLWrapper>
                <img
                  src={`data:image/png;base64,${formData.tenthTc}`}
                  alt={` ${
                    formData.formType === "First_Year"
                      ? "Tenth"
                      : "Twelfth"
                  }Tc`}
                  style={{
                    width: "10.4rem",
                    height: "12.8rem",
                    objectFit: "cover",
                  }}
                />
                <img
                  src={`data:image/png;base64,${formData.tenthMarkSheet}`}
                  alt={` ${
                    formData.formType === "First_Year"
                      ? "Tenth"
                      : "Twelfth"
                  }MarkSheet`}
                  style={{
                    width: "10.4rem",
                    height: "12.8rem",
                    objectFit: "cover",
                  }}
                />
              </SRLWrapper>
            </Row>
          </div>

          <Row
            h_align='flex-end'
            gap='2'
            data-html2canvas-ignore='true'
          >
            <Button
              variant='primary'
              onClick={previous}
              disabled={loading ? true : false}
            >
              Previous
            </Button>

            <Button
              variant={error ? "error" : "primary"}
              onClick={submitHandler}
              disabled={loading ? true : false}
            >
              {loading ? (
                <Spinner />
              ) : error ? (
                <Row wrap='nowrap'>
                  <X
                    size='20'
                    style={{ marginRight: "1rem" }}
                    color='#fff'
                  />
                  <span>Failure</span>
                </Row>
              ) : (
                "Apply"
              )}
            </Button>

            {error && (
              <MessageBox variant='error'>{error}</MessageBox>
            )}
          </Row>
        </div>
      </Section>
    </>
  );
};

export default ConfirmationScreen;
