import React from 'react';

const Button = React.forwardRef((props, ref) => {
  let { variant, children, disabled, ...otherProps } = { ...props };

  disabled = props.disabled ? props.disabled : false;

  return (
    <button
      {...otherProps}
      onClick={props.onClick}
      ref={ref}
      className={`btn ${props.className} ${
        variant === 'primary' ? 'btn__primary' : ''
      } ${variant === 'error' ? 'btn__error' : ''} ${
        variant === 'outlined' ? 'btn__outlined' : ''
      }`}
      disabled={disabled}
    >
      {children}
    </button>
  );
});
export default Button;
