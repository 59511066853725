import React from "react";
import { Step, Wizard } from "react-albus";
import AcademicDetailsScreen from "./AcademicDetailsScreen";
import ConfirmationScreen from "./ConfirmationScreen";
import PersonalDetailsScreen from "./PersonalDetailsScreen";
import SchoolDetailsScreen from "./SchoolDetailsScreen";
import SuccessScreen from "./SuccessScreen";
import AddressScreen from "./AddressScreen";
import Steps from "react-albus/lib/components/Steps";
import FormDataProvider from "../context/FormDataProvider";
import UploadScreen from "./UploadScreen";

const steps = [
  { id: "personalDetails" },
  { id: "addressDetails" },
  { id: "schoolDetails" },
  { id: "academicDetails" },
  { id: "confirmation" },
  { id: "success" },
];

const ApplicationScreen = () => {
  return (
    <FormDataProvider>
      <Wizard>
        <Steps>
          <Step id='personalDetails' render={PersonalDetailsScreen} />
          <Step id='addressDetails' render={AddressScreen} />
          <Step id='schoolDetails' render={SchoolDetailsScreen} />
          <Step id='academicDetails' render={AcademicDetailsScreen} />
          <Step id='uploadFiles' render={UploadScreen} />
          <Step id='confirmation' render={ConfirmationScreen} />
          <Step id='success' render={SuccessScreen} />
        </Steps>
      </Wizard>
    </FormDataProvider>
  );
};
export default ApplicationScreen;
