import React from 'react';
import Col from './Col';
import Row from './Row';
import Library from '../images/facilities/library/lib-2.jpg';
import Hostel from '../images/facilities/hostel/girl-hostel-1.jpg';
import Lab from '../images/facilities/Lab/lab6.jpg';
import Typography from './Typography';
import { ArrowRightCircleFill } from 'react-bootstrap-icons';

const HomeFacilites = () => {
  return (
    <div className="background-primary classroom">
      <div className="circle circle--1"></div>
      <div className="circle circle--2"></div>
      <div className="circle circle--3"></div>
      <div className="circle circle--4"></div>
      <div className="circle circle--5"></div>
      <div className="circle circle--6"></div>
      <div className="circle circle--7"></div>
      <div className="circle circle--8"></div>
      <Row v_align="center">
        <Col span="2">
          <div className="classroom__images">
            <img
              src={Hostel}
              alt="classroom-1"
              className="classroom__image classroom__image--1"
            />
            <img
              src={Lab}
              alt="classroom-2"
              className="classroom__image classroom__image--2"
            />
            <img
              src={Library}
              alt="classroom-3"
              className="classroom__image classroom__image--3"
            />
          </div>
        </Col>
        <Col span="2">
          {' '}
          <div className="classroom__text">
            <div>
              <Typography variant="h2">Our College Facilities</Typography>
              <Typography>
                A Clean and Comfortable rooms that can help focus on studying,
                equipped with various modern facilities that make learning
                awesome.
              </Typography>
              <Row padding="0.5rem" gap="2" v_align="center" wrap="nowrap">
                <ArrowRightCircleFill />
                Spacious classroom
              </Row>
              <Row padding="0.5rem" gap="2" v_align="center" wrap="nowrap">
                <ArrowRightCircleFill />
                Modern Laboratory
              </Row>
              <Row padding="0.5rem" gap="2" v_align="center" wrap="nowrap">
                <ArrowRightCircleFill />
                Rich Library
              </Row>
              <Row padding="0.5rem" gap="2" v_align="center" wrap="nowrap">
                <ArrowRightCircleFill />
                Homely Hostels for Both Boys and Girls
              </Row>
              <Row padding="0.5rem" gap="2" v_align="center" wrap="nowrap">
                <ArrowRightCircleFill />
                Canteen
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default HomeFacilites;
