import React from 'react';
import Row from '../components/Row';
import Col from '../components/Col';
import Section from '../components/Section';
import Typography from '../components/Typography';
import Civil1 from '../images/courses/civil/img-5.jpg';
import Computer from '../images/courses/cse/img-5.jpg';
import AutoMobile from '../images/courses/automobile/img-1.jpg';
import Mechanical from '../images/courses/mech/img-2.jpg';
import Electrical from '../images/courses/ec/img-4.jpg';
import Electronical from '../images/courses/eee/img-2.jpg';

const CoursesScreen = () => {
  return (
    <Section className="courses-screen">
      <Typography variant="h2">Courses</Typography>
      <Row v_align="center">
        <Col span="2">
          <Typography variant="h4">
            Diploma in <span>Civil</span> Engineering (DCE)
          </Typography>
          <Typography variant="body1">
            Kottai Mariamman Polytechnic College, Salem offers 3 Years Full Time
            Diploma / Certification in Diploma Civil Engineering (DCE).Civil
            engineering is a professional engineering discipline that deals with
            the design, construction and maintenance of the physical and
            naturally built environment, including works like Roads, Bridges
            Dams and etc., So we have excellent Laboratories facilities,
            Hydraulic material testing, Concrete Laboratories. Lots of
            opportunities in Government National Highways department, Surveying
            and Constructions.
          </Typography>
        </Col>
        <Col span="2">
          <img
            src={Civil1}
            width="100%"
            alt="DCE"
            style={{ borderRadius: '2rem' }}
          />
        </Col>
      </Row>
      <Row v_align="center">
        <Col span="2">
          <img
            src={Computer}
            width="100%"
            alt="DCSE"
            style={{ borderRadius: '2rem' }}
          />
        </Col>
        <Col span="2">
          <Typography variant="h4">
            Diploma in <span>Computer</span> Engineering (DCT)
          </Typography>
          <Typography variant="body1">
            Kottai Mariamman Polytechnic College, Salem offers 3 Years Full Time
            Professional Courses in Diploma in Computer Engineering
            (DCT).Computer Engineering is a part of engineering deals with
            Software Programming, System Administration, Graphics and, Web
            designing. It Provide an opportunities in software development, App
            development, Networking and Computerized job in all Government
            Sectors.
          </Typography>
        </Col>
      </Row>
      <Row v_align="center">
        <Col span="2">
          <Typography variant="h4">
            Diploma in <span>Automobile</span> Engineering (DAE)
          </Typography>
          <Typography variant="body1">
            Kottai Mariamman Polytechnic College, Salem offers 3 Years Full Time
            Diploma / Certification in Diploma Automobile Engineering (DAE)
            Automobile Engineering is a branch of engineering which deals with
            designing, manufacturing and operating automobile. Its is a Segment
            of vehicle engineering which deals with motorcycles, buses, truck
            etc., Employment opportunities in the following fields, Top
            Multinational Companies and Government Sector like RTO, Brake
            Inspector, TNSTC etc.,
          </Typography>
        </Col>
        <Col span="2">
          <img
            src={AutoMobile}
            width="100%"
            alt="DAE"
            style={{ borderRadius: '2rem' }}
          />
        </Col>
      </Row>
      <Row v_align="center">
        <Col span="2">
          <img
            src={Mechanical}
            width="100%"
            alt="DME"
            style={{ borderRadius: '2rem' }}
          />
        </Col>
        <Col span="2">
          <Typography variant="h4">
            Diploma in <span>Mechanical</span> Engineering (DME)
          </Typography>
          <Typography variant="body1">
            Kottai Mariamman Polytechnic College, Salem offers 2 Years Full Time
            Diploma / Certification in Diploma Mechanical Engineering (DME)
            Mechanical Engineering is to be one of the most demand and high
            class course in the engineering sector. The greatest part of
            Mechanical Engineering Combining is the design and development of
            mechanical system such as Automotive parts, Robots and etc., We
            offer job offers opportunities top companies like TVS Motors, TVS
            Sundaram Clayton, Ashok Leyland etc, and also Government Sector like
            BHEL, TNSTC, NLC..
          </Typography>
        </Col>
      </Row>
      <Row v_align="center">
        <Col span="2">
          <Typography variant="h4">
            Diploma in <span>Electronics and Communication</span> Engineering
            (DECE)
          </Typography>
          <Typography variant="body1">
            Kottai Mariamman Polytechnic College, Salem offers 3 Years Full Time
            Diploma / Certification in Diploma Electronics and Communication
            (DEC).Electrical and Communication Engineering deals with the
            Electronic devices, circuit’s communication equipment’s like
            transmitter, receiver and IC. Electronic is now part of our everyday
            life from the Mobile Phone, Televisions, and Computers. Employment
            Opportunities in Government Sector like BSNL, TNEB, BHEL, etc.,
            Private Sectors to top Software Companies
          </Typography>
        </Col>
        <Col span="2">
          <img
            src={Electrical}
            width="100%"
            alt="DEC"
            style={{ borderRadius: '2rem' }}
          />
        </Col>
      </Row>
      <Row v_align="center">
        <Col span="2">
          <img
            src={Electronical}
            width="100%"
            alt="DME"
            style={{ borderRadius: '2rem' }}
          />
        </Col>
        <Col span="2">
          <Typography variant="h4">
            Diploma in <span>Electrical and Electronical</span> Engineering{' '}
            (DEEE)
          </Typography>
          <Typography variant="body1">
            Kottai Mariamman Polytechnic College, Salem offers 2 Years Full Time
            Diploma / Certification in Diploma Electrical and Electronical
            Engineering (DEEE). Electrical and Electronics Engineering deals
            with the fundamental knowledge in core discipline such as
            Communication, Control Systems, Power Generation and Electrical
            Machines. Employment Opportunities in Government Sector like TNEB,
            Railway and Etc., Private Sector Multinational Companies like TVS
            Motors, Rane Madras Etc.,
          </Typography>
        </Col>
      </Row>
    </Section>
  );
};

export default CoursesScreen;
