import React from 'react';
import Section from '../components/Section';
import Testimonials from '../components/Testimonials';
import HeroContent from '../components/HeroContent';
import HomeFeature from '../components/HomeFeature';
import HomeCourse from '../components/HomeCourse';
import HomeFacilites from '../components/HomeFacilites';

const HomeScreen = () => {
  return (
    <div>
      <HeroContent />
      <Section className="section__features">
        <HomeFeature />
      </Section>
      <Section className="section__courses">
        <HomeCourse />
      </Section>
      <Section className="section__facilities">
        <HomeFacilites />
      </Section>
      <Section className="section__testimonials">
        <Testimonials />
      </Section>
    </div>
  );
};

export default HomeScreen;
