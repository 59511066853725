import React, { useRef, useState } from "react";
import { SRLWrapper } from "simple-react-lightbox";
import ReactPaginate from "react-paginate";
import Typography from "../components/Typography";
import Section from "../components/Section";
import Gallary1 from "../images/gallery/gallery-1.jpg";
import Gallary2 from "../images/gallery/gallery-2.jpg";
import Gallary3 from "../images/gallery/gallery-3.jpg";
import Gallary4 from "../images/gallery/gallery-4.jpg";
import Gallary5 from "../images/gallery/gallery-5.jpg";
import Gallary6 from "../images/gallery/gallery-6.jpg";
import Gallary7 from "../images/gallery/gallery-7.jpg";
import Gallary8 from "../images/gallery/gallery-8.jpg";
import Gallary9 from "../images/gallery/gallery-9.jpg";
import Gallary10 from "../images/gallery/gallery-10.jpg";
import Gallary11 from "../images/gallery/gallery-11.jpg";
import Gallary12 from "../images/gallery/gallery-12.jpg";
import Gallary13 from "../images/gallery/gallery-13.jpg";
import Gallary14 from "../images/gallery/gallery-14.jpg";
import Gallary15 from "../images/gallery/gallery-15.jpg";
import Gallary16 from "../images/gallery/gallery-16.jpg";
import Gallary17 from "../images/gallery/gallery-17.jpg";
import Gallary18 from "../images/gallery/gallery-18.jpg";
import Gallary19 from "../images/gallery/gallery-19.jpg";
import Gallary20 from "../images/gallery/gallery-20.jpg";
import Gallary21 from "../images/gallery/gallery-21.jpg";
import Gallary22 from "../images/gallery/gallery-22.jpg";
import Gallary23 from "../images/gallery/gallery-23.jpg";
import Gallary24 from "../images/gallery/gallery-24.jpg";
import Gallary25 from "../images/gallery/gallery-25.jpg";
import Gallary26 from "../images/gallery/gallery-26.jpg";
import Gallary27 from "../images/gallery/gallery-27.jpg";
import Gallary28 from "../images/gallery/gallery-28.jpg";
import Gallary29 from "../images/gallery/gallery-29.jpg";
import Gallary30 from "../images/gallery/gallery-30.jpg";
import Gallary31 from "../images/gallery/gallery-31.jpg";
import Gallary32 from "../images/gallery/gallery-32.jpg";
import Gallary33 from "../images/gallery/gallery-33.jpg";
import Gallary34 from "../images/gallery/gallery-34.jpg";
import Gallary35 from "../images/gallery/gallery-35.jpg";
import Gallary36 from "../images/gallery/gallery-36.jpg";
import Gallary37 from "../images/gallery/gallery-37.jpg";
import Gallary38 from "../images/gallery/gallery-38.jpg";

import Row from "../components/Row";

const GalleryScreen = () => {
  const gallery = [
    {
      src: Gallary1,
    },
    {
      src: Gallary2,
    },
    {
      src: Gallary3,
    },

    {
      src: Gallary4,
    },
    {
      src: Gallary5,
    },
    {
      src: Gallary6,
    },
    {
      src: Gallary7,
    },

    {
      src: Gallary8,
    },
    {
      src: Gallary9,
    },
    {
      src: Gallary10,
    },
    {
      src: Gallary11,
    },
    {
      src: Gallary12,
    },
    {
      src: Gallary13,
    },
    {
      src: Gallary14,
    },
    {
      src: Gallary15,
    },

    {
      src: Gallary16,
    },
    {
      src: Gallary17,
    },
    {
      src: Gallary18,
    },
    {
      src: Gallary19,
    },
    {
      src: Gallary20,
    },
    {
      src: Gallary21,
    },
    {
      src: Gallary22,
    },
    {
      src: Gallary23,
    },
    {
      src: Gallary24,
    },
    {
      src: Gallary25,
    },
    {
      src: Gallary26,
    },
    {
      src: Gallary27,
    },
    {
      src: Gallary28,
    },
    {
      src: Gallary29,
    },
    {
      src: Gallary30,
    },
    {
      src: Gallary31,
    },

    {
      src: Gallary32,
    },
    {
      src: Gallary33,
    },
    {
      src: Gallary34,
    },
    {
      src: Gallary35,
    },
    {
      src: Gallary36,
    },
    {
      src: Gallary37,
    },
    {
      src: Gallary38,
    },
  ];
  const paginateRef = useRef();

  const [currentPage, setCurrentPage] = useState(1);

  let imgPerPage = 20;
  let indexOfLastImg = currentPage * imgPerPage;
  let indexOfFirstImg = indexOfLastImg - imgPerPage;
  const currentImgs = gallery.slice(indexOfFirstImg, indexOfLastImg);
  const handleClick = (data) => {
    setCurrentPage(data.selected + 1);
  };

  return (
    <Section>
      <Typography variant='h2'>Gallery</Typography>
      <SRLWrapper>
        <div className='gallery'>
          {currentImgs.map((item, i) => (
            <figure
              className={`gallery__item gallery__item--${i + 1}`}
              key={i}
            >
              <img
                src={item.src}
                alt={i + 1}
                className='gallery__img'
              />
            </figure>
          ))}
        </div>
      </SRLWrapper>

      {gallery.length / imgPerPage > 1 && (
        <Row gap='2' h_align='center' className='pagination'>
          <ReactPaginate
            ref={paginateRef}
            previousLabel={"Prev"}
            nextLabel={"Next"}
            pageCount={gallery.length / imgPerPage}
            onPageChange={handleClick}
            pageClassName='btn btn__outlined'
            nextClassName='btn btn__outlined'
            disabledClassName='disabled'
            previousClassName='btn btn__outlined'
            activeClassName='btn_primary'
          />
        </Row>
      )}
    </Section>
  );
};

export default GalleryScreen;
