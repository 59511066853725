import React from 'react';

const Section = React.forwardRef((props, ref) => {
  const className = props.className ? props.className : '';
  return (
    <section className={`${className ? className : ''}`} {...props} ref={ref}>
      {props.children}
    </section>
  );
});

export default Section;
