import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Stepper from "react-stepper-horizontal";
import Typography from "../components/Typography";
import Button from "../components/Button";
import ItemForm from "../components/forms/ItemForm";
import RadioButton from "../components/forms/RadioButton";
import Row from "../components/Row";
import Col from "../components/Col";
import Select from "../components/forms/Select";
import Section from "../components/Section";
import Calendar from "react-calendar";
import { Calendar3 } from "react-bootstrap-icons";
import { formDataContext } from "../context/FormDataProvider";
import "filepond/dist/filepond.min.css";
const PersonalDetailsScreen = ({ next }) => {
  const { formData, setFormData } = useContext(formDataContext);
  const [windowWidth, setWindowWidth] = useState();
  const { type } = useParams();
  useEffect(() => {
    setFormData({ ...formData, formType: type });
  }, [type]);

  const districtList = [
    ["Salem", "Salem"],
    ["Ariyalur", "Ariyalur"],
    ["Chengalpet", "Chengalpet"],
    ["Chennai", "Chennai"],
    ["Coimbatore", "Coimbatore"],
    ["Cuddalore", "Cuddalore"],
    ["Dharmapuri", "Dharmapuri"],
    ["Dindigul", "Dindigul"],
    ["Erode", "Erode"],
    ["Kallakurichi", "Kallakurichi"],
    ["Kanchipuram", "Kanchipuram"],
    ["Kanyakumari", "Kanyakumari"],
    ["Karur", "Karur"],
    ["Krishnagiri", "Krishnagiri"],
    ["Madurai", "Madurai"],
    ["Nagapattinam", "Nagapattinam"],
    ["Namakkal", "Namakkal"],
    ["Nilgiris", "Nilgiris"],
    ["Perambalur", "Perambalur"],
    ["Pudukkottai", "Pudukkottai"],
    ["Ramanathapuram", "Ramanathapuram"],
    ["Ranipet", "Ranipet"],
    ["Sivaganga", "Sivaganga"],
    ["Tenkasi", "Tenkasi"],
    ["Thanjavur", "Thanjavur"],
    ["Theni", "Theni"],
    ["Thoothukudi", "Thoothukudi"],
    ["Tiruchirappalli", "Tiruchirappalli"],
    ["Tirunelveli", "Tirunelveli"],
    ["Tirupathur", "Tirupathur"],
    ["Tiruppur", "Tiruppur"],
    ["Tiruvallur", "Tiruvallur"],
    ["Tiruvannamalai", "Tiruvannamalai"],
    ["Tiruvarur", "Tiruvarur"],
    ["Vellore", "Vellore"],
    ["Viluppuram", "Viluppuram"],
    ["Virudhunagar", "Virudhunagar"],
  ];
  const genderTypes = { Male: "Male", Female: "Female" };

  const courses = [
    ["DCE", "DCE"],
    ["DAE", "DAE"],
    ["DME", "DME"],
    ["DEEE", "DEEE"],
    ["DECE", "DECE"],
    ["DCT", "DCT"],
  ];
  const communityTypes = [
    ["ST", "ST"],
    ["SC", "SC"],
    ["MBC & DNC", "MBC & DNC"],
    ["BC", "BC"],
    ["OC", "OC"],
  ];

  const buttonDisabled = !(
    formData.applicantName &&
    formData.parentName &&
    formData.course &&
    formData.gender &&
    formData.aadharNumber &&
    formData.mobile &&
    formData.nativeDistrict &&
    formData.dob &&
    formData.placeOfBirth &&
    formData.nationality &&
    formData.nativeDistrict &&
    formData.community &&
    formData.caste
  );
  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };
  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () =>
      window.removeEventListener("resize", updateDimensions);
  }, []);

  const steps =
    windowWidth > 600
      ? [
          { title: "Personal Details" },
          { title: "Address Details" },
          { title: "School Details" },
          { title: "Academic Details" },
          { title: "Upload Files" },
          { title: "Confirmation" },
        ]
      : [
          { title: "" },
          { title: "" },
          { title: "" },
          { title: "" },
          { title: "" },
          { title: "" },
        ];
  return (
    <Section>
      <div style={{ marginBottom: "50px" }}>
        <Stepper
          steps={steps}
          activeStep={0}
          activeColor='#5bb462'
          completeColor='#5bb462'
          completeTitleColor='#5bb462'
          activeTitleColor='#5bb462'
          titleFontSize={14}
        />
      </div>
      <Typography variant='h4'>Personal Details</Typography>
      <Row>
        <Col span='2'>
          <div className='form'>
            <ItemForm
              label='Applicant Name'
              name='applicantName'
              value={formData.applicantName}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  applicantName: e.target.value,
                });
              }}
              required
            />
            <ItemForm
              label='Parent Name'
              name='parentName'
              value={formData.parentName}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  parentName: e.target.value,
                })
              }
              required
            />
            <Select
              label='Course Applied'
              name='course'
              value={formData.course}
              onChange={(e) =>
                setFormData({ ...formData, course: e.target.value })
              }
              arr={[...courses]}
              required
            ></Select>
            <div className='radio'>
              <div>Gender</div>
              <Row v_align='center' gap='2'>
                {Object.entries(genderTypes).map(([value, name]) => (
                  <RadioButton
                    key={value}
                    label={value}
                    name='gender'
                    value={value}
                    checked={formData.gender === value}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        gender: e.target.value,
                      })
                    }
                    type='radio'
                  />
                ))}
              </Row>
            </div>
            <ItemForm
              label='Aadhar Number'
              name='aadharNumber'
              value={formData.aadharNumber}
              maxLength='12'
              onChange={(e) => {
                const regExp = /^[0-9\b]+$/;
                if (
                  e.target.value === "" ||
                  regExp.test(e.target.value)
                ) {
                  setFormData({
                    ...formData,
                    aadharNumber: e.target.value,
                  });
                }
              }}
              onBlur={(e) => {
                const regExp = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;
                if (!e.target.value.match(regExp)) {
                  e.target.style.border = "1px solid red";
                  e.target.parentElement.nextElementSibling.style.visibility =
                    "visible";
                } else {
                  e.target.style.border = "1px solid #d1d9d9";
                  e.target.parentElement.nextElementSibling.style.visibility =
                    "hidden";
                }
              }}
              required
              variant='aadhar'
            />
            <ItemForm
              label='Mobile Number'
              name='mobile'
              value={formData.mobile}
              maxLength='10'
              pattern='\d'
              variant='mobile'
              onBlur={(e) => {
                if (e.target.value.length < 10) {
                  e.target.style.border = "1px solid red";
                  e.target.parentElement.nextElementSibling.style.visibility =
                    "visible";
                } else {
                  e.target.style.border = "1px solid #d1d9d9";
                  e.target.parentElement.nextElementSibling.style.visibility =
                    "hidden";
                }
              }}
              onChange={(e) => {
                const regExp = /^[0-9\b]+$/;
                if (
                  e.target.value === "" ||
                  regExp.test(e.target.value)
                ) {
                  setFormData({
                    ...formData,
                    mobile: e.target.value,
                  });
                }
              }}
              required
            />
            <ItemForm
              type='text'
              name='motherTongue'
              label='Mother Tongue'
              value={formData.motherTongue}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  motherTongue: e.target.value,
                })
              }
              disabled
              required
            />
          </div>
        </Col>
        <Col span='2'>
          <div className='form'>
            <div className='calendar-input'>
              <Row>
                <ItemForm
                  value={formData.dob}
                  disabled
                  name='dob'
                  label='DOB'
                />
                <Calendar3
                  className='calendar-input__icon'
                  size='20'
                  onClick={(e) =>
                    setFormData({
                      ...formData,
                      dobCalendar: !formData.dobCalendar,
                    })
                  }
                />
              </Row>
              {formData.dobCalendar && (
                <Calendar
                  onClickDay={(e) => {
                    setFormData({
                      ...formData,
                      dob: e.toLocaleString("default", {
                        day: "2-digit",
                        month: "long",
                        year: "numeric",
                      }),
                      dobCalendar: false,
                    });
                  }}
                />
              )}
            </div>
            <ItemForm
              type='text'
              name='placeOfBirth'
              label='Place Of Birth'
              value={formData.placeOfBirth}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  placeOfBirth: e.target.value,
                })
              }
              required
            />
            <ItemForm
              type='text'
              name='nationality'
              label='Nationality'
              value={formData.nationality}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  nationality: e.target.value,
                })
              }
              disabled
              required
            />
            <Select
              label='Native District'
              name='nativeDistrict'
              value={formData.nativeDistrict}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  nativeDistrict: e.target.value,
                })
              }
              arr={[...districtList]}
              required
            ></Select>
            <Select
              label='Name of the Community'
              name='community'
              value={formData.community}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  community: e.target.value,
                })
              }
              arr={[...communityTypes]}
              required
            ></Select>
            <ItemForm
              type='text'
              name='caste'
              label='Caste'
              value={formData.caste}
              onChange={(e) =>
                setFormData({ ...formData, caste: e.target.value })
              }
              required
            />
          </div>
        </Col>
      </Row>

      <Row h_align='flex-end'>
        <Button
          variant='primary'
          onClick={next}
          disabled={buttonDisabled ? true : false}
        >
          Next
        </Button>
      </Row>
    </Section>
  );
};

export default PersonalDetailsScreen;
