import React, { useState } from 'react';
import Hero from '../images/hero.jpg';
import Button from './Button';
import Row from './Row';
import Shape1 from '../images/square-grey-dots.svg';
import Shape2 from '../images/red-square-triangle.svg';
import Shape3 from '../images/yellow-diamond.svg';
import Shape4 from '../images/circle-green-multiple.svg';
import Shape5 from '../images/triangle-yellow-dots.svg';
import Shape6 from '../images/red-zigzag.svg';
import Shape7 from '../images/green-zigzag.svg';
import Shape8 from '../images/grey-dot-single.svg';
import Shape9 from '../images/red-dot-single.svg';
import Shape10 from '../images/green-dot-single.svg';
import Shape11 from '../images/yellow-dot-single.svg';
import Typography from './Typography';
import Col from './Col';
import Section from './Section';
import Modal from './Modal';
import Card, { CardActions, CardContent, CardHeader } from './Card';
import {
  Building,
  LightbulbFill,
  TrophyFill,
} from 'react-bootstrap-icons';
import RadioButton from './forms/RadioButton';
import { useHistory } from 'react-router-dom';

const HeroContent = () => {
  const [modalStatus, setModalStatus] = useState(false);
  const history = useHistory();
  const [option, setOption] = useState('First_Year');
  const applicationTypes = {
    First_Year: 'First Year',
    Second_Year: 'Second Year',
  };
  const applyHandler = () => {
    setModalStatus(true);
  };
  return (
    <>
      {modalStatus && (
        <Modal>
          <Card border='none' boxShadow='none'>
            <CardHeader>Choose the Application Type</CardHeader>
            <CardContent>
              <Row v_align='center'>
                {Object.entries(applicationTypes).map(
                  ([key, value]) => (
                    <RadioButton
                      key={value}
                      label={value}
                      name='applicationType'
                      value={key}
                      onChange={(e) => {
                        setOption(e.target.value);
                      }}
                      checked={option === key}
                      type='radio'
                    />
                  )
                )}
              </Row>
            </CardContent>
            <CardActions
              justify='flex-end'
              padding='1rem 0 0 0'
              gap='2rem'
            >
              <Button
                onClick={() => {
                  setModalStatus(false);
                }}
                variant='outlined'
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  history.push(`/type/${option}`);
                }}
                variant='primary'
              >
                Ok
              </Button>
            </CardActions>
          </Card>
        </Modal>
      )}
      <Section className='hero'>
        <img
          src={Shape1}
          alt=''
          className='hero__image--abstract hero__image--abstract-1'
        />
        <img
          src={Shape5}
          alt=''
          className='hero__image--abstract hero__image--abstract-5'
        />
        <img
          src={Shape2}
          alt=''
          className='hero__image--abstract hero__image--abstract-2'
        />
        <img
          src={Shape3}
          alt=''
          className='hero__image--abstract hero__image--abstract-3'
        />
        <img
          src={Shape4}
          alt=''
          className='hero__image--abstract hero__image--abstract-4'
        />
        <img
          src={Shape6}
          alt=''
          className='hero__image--abstract hero__image--abstract-6'
        />
        <img
          src={Shape7}
          alt=''
          className='hero__image--abstract hero__image--abstract-7'
        />
        <img
          src={Shape8}
          alt=''
          className='hero__image--abstract hero__image--abstract-8'
        />
        <img
          src={Shape9}
          alt=''
          className='hero__image--abstract hero__image--abstract-9'
        />
        <img
          src={Shape10}
          alt=''
          className='hero__image--abstract hero__image--abstract-10'
        />
        <img
          src={Shape11}
          alt=''
          className='hero__image--abstract hero__image--abstract-11'
        />
        <Row v_align='center'>
          <Col span='2' className='hero__text-container'>
            <div className='hero__text'>
              <Typography
                variant='h1'
                className='hero__text--heading'
              >
                KOTTAI MARIYAMMAN
              </Typography>
              <Typography variant='h2'>
                POLYTECHNIC COLLEGE
              </Typography>
              <Typography variant='h6'>
                (Approved by AICTE New Delhi & Government of <br></br>{' '}
                TamilNadu and Affliated to DOTE, Chennai)
              </Typography>
              <Typography
                variant='body1'
                className='hero__text--content'
              >
                The college aims to strengthening ability of
                reconstructing new era of youth society by creative
                and innovative teaching and organizing engineering
                education programs.
              </Typography>
              <Button
                variant='primary'
                className='btn__primary-shadowed'
                onClick={applyHandler}
              >
                Join Now
              </Button>
            </div>
          </Col>
          <Col span='2' className='hero__image-container'>
            <img src={Hero} alt='KMPTC' className='hero__image' />
            <div className='hero__features-panel'>
              <div className='hero__feature'></div>
              <div className='hero__feature'>
                <LightbulbFill size='24' />
                Quality Education
              </div>
              <div className='hero__feature'>
                <TrophyFill size='24' />
                More Job Opportunities
              </div>
              <div className='hero__feature'>
                <Building size='24' />
                Good Infrastructure
              </div>
              <div className='hero__feature'></div>
            </div>
          </Col>
        </Row>
      </Section>
    </>
  );
};

export default HeroContent;
