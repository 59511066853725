import React from 'react';

const Select = ({ label, arr, ...others }) => {
  return (
    <div>
      <label>{label}</label>
      <select {...others}>
        <option disabled value="">
          Select
        </option>
        {arr.map(([value, name]) => (
          <option value={value} key={name}>
            {name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
