import React from 'react';

const RadioButton = ({ label, children, ...otherProps }) => {
  return (
    <div className="radio">
      <label>
        <input type="radio" {...otherProps} checked={otherProps.checked} />
        <span className="checkmark"></span>
        {label}
      </label>
    </div>
  );
};

export default RadioButton;
