import React from 'react';

const variantsMapping = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  subHeading1: 'h6',
  subHeading2: 'h6',
  body1: 'p',
  body2: 'p',
};

const Typography = ({ variant, color, children, ...props }) => {
  const Component = variant ? variantsMapping[variant] : 'p';
  return (
    <Component
      className={`typography--variant-${variant} typography--color-${color} ${props.className}`}
      {...props}
    >
      {children}
    </Component>
  );
};

export default Typography;
