import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
} from "redux";
import thunk from "redux-thunk";
import {
  contactUsMailReducer,
  registerApplicationReducer,
} from "./reducers/taskReducer";

const reducers = combineReducers({
  contactUsMail: contactUsMailReducer,
  registerApplication: registerApplicationReducer,
});

const composeEnhancer =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducers,
  composeEnhancer(applyMiddleware(thunk))
);
export default store;
