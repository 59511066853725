import React, { useState } from 'react';
import Button from '../components/Button';
import Col from '../components/Col';
import Row from '../components/Row';
import Section from '../components/Section';
import Typography from '../components/Typography';

const FeedbackScreen = () => {
  const [name, setName] = useState('');
  const [dept, setDept] = useState('');
  const [email, setEmail] = useState('');
  const [year, setYear] = useState('');
  const [contactNo, setContactNo] = useState('');
  const [feedback, setFeedBack] = useState('');
  const submitHandler = () => {};
  return (
    <Section className='contact-us'>
      <Typography variant='h2'>Feedback</Typography>
      <Row>
        <Col span='2'>
          <Row style={{ marginBottom: '2rem', width: '80%' }}>
            <label>Student Name</label>
            <input
              type='text'
              placeholder='Enter Student Name'
              name='name'
              required
              onChange={(e) => {
                setName(e.target.value);
              }}
            ></input>
          </Row>
          <Row style={{ marginBottom: '2rem', width: '80%' }}>
            <label>Email</label>
            <input
              type='text'
              placeholder='Enter Email'
              name='email'
              required
              onChange={(e) => {
                setName(e.target.value);
              }}
            ></input>
          </Row>

          <Row style={{ marginBottom: '2rem', width: '80%' }}>
            <label>Feedback</label>
            <input
              type='text'
              placeholder='Enter Feedback'
              name='feedback'
              required
              onChange={(e) => {
                setName(e.target.value);
              }}
            ></input>
          </Row>
        </Col>
        <Col span='2'>
          <Row style={{ marginBottom: '2rem', width: '80%' }}>
            <label>Department</label>
            <input
              type='text'
              placeholder='Enter Department'
              name='dept'
              required
              onChange={(e) => {
                setName(e.target.value);
              }}
            ></input>
          </Row>
          <Row style={{ marginBottom: '2rem', width: '80%' }}>
            <label>Year of Study</label>
            <input
              type='text'
              placeholder='Enter Year of Study'
              name='yearOfStudy'
              required
              onChange={(e) => {
                setName(e.target.value);
              }}
            ></input>
          </Row>{' '}
          <Row style={{ marginBottom: '2rem', width: '80%' }}>
            <label>Contact Number</label>
            <input
              type='text'
              placeholder='Enter Contact Number'
              name='contact_no'
              required
              onChange={(e) => {
                setName(e.target.value);
              }}
            ></input>
          </Row>{' '}
          <Row h_align='flex-end'>
            <Button
              variant={'primary'}
              style={{ marginRight: '20%' }}
            >
              Submit
            </Button>
          </Row>
        </Col>
      </Row>
    </Section>
  );
};

export default FeedbackScreen;
