import React from 'react';
import { CaretRightFill } from 'react-bootstrap-icons';
import { Carousel } from 'react-responsive-carousel';
import Col from '../components/Col';
import Row from '../components/Row';
import Section from '../components/Section';
import Typography from '../components/Typography';
import lib1 from '../images/facilities/library/lib-1.jpg';
import lib2 from '../images/facilities/library/lib-2.jpg';
import lib3 from '../images/facilities/library/lib-5.jpg';
import lib4 from '../images/facilities/library/lib-4.jpg';
import trans1 from '../images/facilities/transport/trans-1.jpg';
import trans2 from '../images/facilities/transport/trans-5.jpg';
import trans3 from '../images/facilities/transport/trans-3.jpg';
import trans4 from '../images/facilities/transport/trans-4.jpg';
import lab1 from '../images/facilities/Lab/lab1.jpg';
import lab2 from '../images/facilities/Lab/lab2.jpg';
import lab3 from '../images/facilities/Lab/lab3.jpg';
import lab4 from '../images/facilities/Lab/lab4.jpg';
import lab5 from '../images/facilities/Lab/lab5.jpg';

const FacilitiesScreen = () => {
  return (
    <Section className="facilities">
      <Typography variant="h3">Facilities</Typography>
      <Row v_align="center" style={{ marginBottom: '4rem' }}>
        <Col span="2" className="facilities__text">
          <Typography variant="h4">
            Our <span>Library</span>
          </Typography>
          <ul className="facilities__list">
            <li className="facilities__list-item">
              <CaretRightFill
                size="15"
                style={{ marginRight: '10px' }}
                className="list__icons"
              />
              The Library has a rich collection of books on Engineering and
              Technology including Computer Engineering, Electrical and
              Electronics Engineering, Electronics & Communication Engineering
              and Mechanical Engineering.
            </li>
            <li className="facilities__list-item">
              <CaretRightFill
                size="15"
                style={{ marginRight: '10px' }}
                className="list__icons"
              />
              Besides, library also has a good collection of books in the areas
              of Humanities and Sciences.
            </li>
          </ul>
          <div>
            <Typography variant="h6">Library Working Hours</Typography>
            <ul className="facilities__list">
              <li className="facilities__list-item">
                <CaretRightFill
                  size="15"
                  style={{ marginRight: '10px' }}
                  className="list__icons"
                />
                8:00 AM - 5:00 PM (Week days)
              </li>
              <li className="facilities__list-item">
                <CaretRightFill
                  size="15"
                  style={{ marginRight: '10px' }}
                  className="list__icons"
                />
                Our Polytechnic College Library possess 6000 books in an ordered
                manner of various disciplines. It is computerized for the
                benefit of the students and is working 12 hours per day
              </li>
            </ul>
          </div>
        </Col>
        <Col span="2">
          <Carousel
            showArrows={false}
            showIndicators={false}
            showStatus={false}
            showThumbs={false}
            infiniteLoop={true}
            autoPlay={true}
          >
            <img
              src={lib1}
              alt="KMPTC Library"
              style={{ borderRadius: '2rem' }}
            />
            <img
              src={lib2}
              alt="KMPTC Library"
              style={{ borderRadius: '2rem' }}
            />
            <img
              src={lib3}
              alt="KMPTC Library"
              style={{ borderRadius: '2rem' }}
            />
            <img
              src={lib4}
              alt="KMPTC Library"
              style={{ borderRadius: '2rem' }}
            />
          </Carousel>
        </Col>
      </Row>

      <Row v_align="center" style={{ marginBottom: '4rem' }}>
        <Col span="2">
          <Carousel
            showArrows={false}
            showIndicators={false}
            showStatus={false}
            showThumbs={false}
            infiniteLoop={true}
            autoPlay={true}
          >
            <img
              src={trans1}
              alt="KMPTC Transport"
              style={{ borderRadius: '2rem' }}
            />
            <img
              src={trans2}
              alt="KMPTC Transport"
              style={{ borderRadius: '2rem' }}
            />
            <img
              src={trans3}
              alt="KMPTC Transport"
              style={{ borderRadius: '2rem' }}
            />
            <img
              src={trans4}
              alt="KMPTC Transport"
              style={{ borderRadius: '2rem' }}
            />
          </Carousel>
        </Col>
        <Col span="2" className="facilities__text">
          <Typography variant="h4">
            <span>Transport</span> Facilities
          </Typography>
          <ul className="facilities__list">
            <li className="facilities__list-item">
              <CaretRightFill
                size="15"
                style={{ marginRight: '10px' }}
                className="list__icons"
              />
              A full fledged Transport department functions in the college with
              buses to provide transport facility to students and staff from
              various places. This service is offered ensuring a hassle-free and
              safe transportation.
            </li>
            <li className="facilities__list-item">
              <CaretRightFill
                size="15"
                style={{ marginRight: '10px' }}
                className="list__icons"
              />
              The College runs 6 buses for providing transport facility to
              students from various places in the city. The number of buses will
              be increased corresponding to the increase in the intake of
              students.
            </li>
          </ul>
        </Col>
      </Row>
      <Row style={{ marginBottom: '4rem' }} v_align="center">
        <Col span="2" className="facilities__text">
          <Typography variant="h4">
            Our <span>Labs</span>
          </Typography>
          <ul className="facilities__list">
            <li className="facilities__list-item">
              <CaretRightFill
                size="15"
                style={{ marginRight: '10px' }}
                className="list__icons"
              />
              We are having the efficient and sophisticated Labs to train the
              students to get practical knowledge in their respective courses.
            </li>
            <li className="facilities__list-item">
              <CaretRightFill
                size="15"
                style={{ marginRight: '10px' }}
                className="list__icons"
              />
              Computer Engineer's trained with real time project in either
              Hardware or Software level using the Project Lab.
            </li>
            <li className="facilities__list-item">
              <CaretRightFill
                size="15"
                style={{ marginRight: '10px' }}
                className="list__icons"
              />
              For Mechanical,Electrical and other departments utilize the Labs
              to deliver their skills as a project using their corresponding
              Laboratory.
            </li>
            <li className="facilities__list-item">
              <CaretRightFill
                size="15"
                style={{ marginRight: '10px' }}
                className="list__icons"
              />
              It helps the students to get the idea on their platform of their
              course.
            </li>
            <li className="facilities__list-item">
              <CaretRightFill
                size="15"
                style={{ marginRight: '10px' }}
                className="list__icons"
              />
              Here we are the dedicated staff and energetic Lab assistants to
              assist and motivate the students by Individual.
            </li>
          </ul>
        </Col>
        <Col span="2">
          <Carousel
            showArrows={false}
            showIndicators={false}
            showStatus={false}
            showThumbs={false}
            infiniteLoop={true}
            autoPlay={true}
          >
            <img src={lab1} alt="KMPTC Lab" style={{ borderRadius: '2rem' }} />
            <img src={lab2} alt="KMPTC Lab" style={{ borderRadius: '2rem' }} />
            <img src={lab3} alt="KMPTC Lab" style={{ borderRadius: '2rem' }} />
            <img src={lab4} alt="KMPTC Lab" style={{ borderRadius: '2rem' }} />
            <img src={lab5} alt="KMPTC Lab" style={{ borderRadius: '2rem' }} />
          </Carousel>
        </Col>
      </Row>
    </Section>
  );
};

export default FacilitiesScreen;
