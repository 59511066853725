import React from 'react';

const Col = ({ span, col, ...props }) => {
  span = span ? span : '';
  col = col ? col : '';
  const className = props.className ? props.className : '';
  return (
    <div className={`column span-${span} col-${col} ${className}`}>
      {props.children}
    </div>
  );
};

export default Col;
