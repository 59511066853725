import React from "react";
import Typography from "./Typography";

const Card = ({
  children,
  width,
  borderRadius,
  height,
  layout,
  boxShadow,
  border,
  padding,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: layout === "horizontal" ? "row" : "column",
        width: width + "rem",
        height: height ? height + "rem" : "100%",
        borderRadius: borderRadius + "rem",
        overflow: "hidden",
        backgroundColor: "#fff",
        boxShadow: boxShadow
          ? boxShadow
          : `0 5px 12px rgba(91, 180, 98,0.075)`,
        border: border
          ? border
          : "1px solid  rgba(91, 180, 98,0.075)",
        cursor: "default",
        padding: padding ? padding + "rem" : 0,
      }}
    >
      {children}
    </div>
  );
};
export const CardContent = ({ children, padding }) => {
  return (
    <div
      style={{
        padding: padding ? padding + "rem" : 0,
        color: "inherit",
        flexGrow: 1,
      }}
    >
      {children}
    </div>
  );
};

export const CardMedia = ({ height, width, src }) => {
  return (
    <img
      src={src}
      alt=''
      style={{
        height: height ? height + "rem" : "100%",
        width: width ? width + "rem" : "100%",
        objectFit: "cover",
      }}
    />
  );
};

export const CardHeader = ({ children, padding, align, justify }) => {
  return (
    <Typography
      variant='h6'
      style={{
        display: "flex",
        justifyContent: justify,
        alignItems: align ? align : "center",
        padding: padding ? padding + "rem" : "",
      }}
    >
      {children}
    </Typography>
  );
};

export const CardActions = ({
  children,
  justify,
  align,
  padding,
  gap,
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: justify,
        alignItems: align ? align : "center",
        padding: padding ? padding : "",
        gap: gap ? gap : "",
      }}
    >
      {children}
    </div>
  );
};

export default Card;
