import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Row from "../components/Row";
import Section from "../components/Section";
import Button from "../components/Button";
import Typography from "../components/Typography";
import Col from "../components/Col";
import {
  Check2,
  EnvelopeFill,
  GeoAltFill,
  PhoneFill,
  TelephoneFill,
  X,
} from "react-bootstrap-icons";
import { sendContactUsMail } from "../actions/taskActions";
import { SEND_CONTACT_US_MAIL_RESET } from "../constants/taskConstants";
import Spinner from "../components/Spinner";
import MessageBox from "../components/MessageBox";
import Card, { CardContent } from "../components/Card";

const ContactUsScreen = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const { loading, success, error } = useSelector(
    (state) => state.contactUsMail
  );
  const dispatch = useDispatch();
  const submitHandler = (e) => {
    e.preventDefault();
    const validationExp =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (name && email && message) {
      if (email.match(validationExp)) {
        dispatch(sendContactUsMail({ name, email, message }));
      } else {
        alert("Please enter valid email address");
      }
    } else {
      alert("Please fill all fields");
    }
  };

  useEffect(() => {
    if (success || error) {
      const timer = setTimeout(() => {
        dispatch({ type: SEND_CONTACT_US_MAIL_RESET });
      }, 5000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [success, error, dispatch]);
  return (
    <Section className='contact-us'>
      <Row v_align='center'>
        <Col span='2' className='contact-us__img--outer'>
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3907.4310761665824!2d78.2722992146638!3d11.663831291723104!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3babf2bc24db25eb%3A0x1cf624b85666a341!2sKottai%20Mariyamman%20Polytechnic%20College!5e0!3m2!1sen!2sin!4v1626240228026!5m2!1sen!2sin'
            width='100%'
            height='400px'
            style={{ border: "0" }}
            allowFullScreen=''
            loading='lazy'
            title='KMPTC'
          ></iframe>
        </Col>
        <Col span='2' className='contact-us__form--outer'>
          <form
            className='contact-us__form form'
            onSubmit={submitHandler}
          >
            <Row>
              <Typography variant='h2'>
                <span>Contact</span> Us
              </Typography>
            </Row>
            <Row>
              <label>Enter Name</label>
              <input
                type='text'
                placeholder='Enter Name'
                name='name'
                required
                onChange={(e) => {
                  setName(e.target.value);
                }}
              ></input>
            </Row>
            <Row>
              <label>Enter Email</label>
              <input
                type='email'
                placeholder='Enter Email'
                name='email'
                required
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              ></input>
            </Row>
            <Row>
              <label>Enter Message</label>
              <textarea
                type='text'
                placeholder='Enter Message'
                name='message'
                required
                rows='10'
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
              ></textarea>
            </Row>
            <Row>
              <Button variant={error ? "error" : "primary"}>
                {loading ? (
                  <Spinner />
                ) : error ? (
                  <Row wrap='nowrap'>
                    <X
                      size='20'
                      style={{ marginRight: "1rem" }}
                      color='#fff'
                    />
                    <span>Failure</span>
                  </Row>
                ) : success ? (
                  <Row wrap='nowrap'>
                    <Check2
                      size='20'
                      style={{ marginRight: "1rem" }}
                      color='#fff'
                    />
                    <span>Success</span>
                  </Row>
                ) : (
                  "Send"
                )}
              </Button>
            </Row>
            {error && (
              <MessageBox variant='error'>{error}</MessageBox>
            )}
          </form>
        </Col>
      </Row>
      <Row h_align='center' gap='2' v_align='stretch'>
        <Col
          col='3'
          style={{
            boxShadow: `0 5px 12px rgba(91, 180, 98,0.075)`,
            border: "1px solid  rgba(91, 180, 98,0.075)",
          }}
          className='contact-us-card'
        >
          <Card padding='4' borderRadius='2'>
            <div className='contact-us-card__header'>Call Us</div>
            <CardContent>
              <Row wrap='nowrap'>
                <TelephoneFill
                  size='15'
                  style={{ marginRight: "10px", marginTop: "5px" }}
                />
                <div>
                  0427-2492538,
                  <br /> 0427-2482848
                </div>
              </Row>
              <Row wrap='nowrap'>
                <PhoneFill
                  size='15'
                  style={{ marginRight: "10px", marginTop: "5px" }}
                />
                <div>
                  +91-8012507075,
                  <br /> +91-9940301069
                </div>
              </Row>
            </CardContent>
          </Card>
        </Col>
        <Col
          col='3'
          style={{
            boxShadow: `0 5px 12px rgba(91, 180, 98,0.075)`,
            border: "1px solid  rgba(91, 180, 98,0.075)",
          }}
          className='contact-us-card'
        >
          <Card padding='4' borderRadius='2'>
            <div className='contact-us-card__header'>Address</div>
            <CardContent>
              <Row wrap='nowrap'>
                <GeoAltFill
                  size='15'
                  style={{ marginRight: "10px", marginTop: "5px" }}
                />
                <div>
                  45-A ,Karipatti (Via), <br /> Minnampalli (PO),
                  <br />
                  Minnampalli,Salem
                  <br />
                  Tamilnadu( East ),India-636106
                </div>
              </Row>
            </CardContent>
          </Card>
        </Col>
        <Col
          col='3'
          style={{
            boxShadow: `0 5px 12px rgba(91, 180, 98,0.075)`,
            border: "1px solid  rgba(91, 180, 98,0.075)",
          }}
          className='contact-us-card'
        >
          <Card padding='4' borderRadius='2'>
            <div className='contact-us-card__header'>Mail Us</div>
            <CardContent>
              <Row wrap='nowrap'>
                <EnvelopeFill
                  size='15'
                  style={{ marginRight: "10px", marginTop: "5px" }}
                />
                <div>kottaimari@gmail.com</div>
              </Row>
            </CardContent>
          </Card>
        </Col>
      </Row>
    </Section>
  );
};

export default ContactUsScreen;
