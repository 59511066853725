import React from "react";
import Row from "../Row";

const ItemForm = ({
  label,
  variant,
  children,
  type = "text",
  ...otherProps
}) =>
  type === "radio" ? (
    <Row v_align='center' gap='1'>
      <input type={type} {...otherProps} />
      <label>{label}</label>
    </Row>
  ) : variant === "mobile" ? (
    <div style={{ flexGrow: 1 }}>
      <label>{label}</label>
      <div
        style={{
          display: "flex",
          flexWrap: "nowrap",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        +91
        <input type={type} {...otherProps} maxLength='10' />
      </div>
      <small style={{ color: "red", visibility: "hidden" }}>
        Invalid Mobile Number
      </small>
    </div>
  ) : variant === "aadhar" ? (
    <div style={{ flexGrow: 1 }}>
      <label>{label}</label>
      <div>
        <input type={type} {...otherProps} />
      </div>

      <small style={{ color: "red", visibility: "hidden" }}>
        Invalid Aadhar Number
      </small>
    </div>
  ) : variant === "pincode" ? (
    <div style={{ flexGrow: 1 }}>
      <label>{label}</label>
      <div>
        <input type={type} {...otherProps} maxLength='6' />
      </div>
      <small style={{ color: "red", visibility: "hidden" }}>
        Invalid Pincode
      </small>
    </div>
  ) : (
    <div style={{ flexGrow: 1 }}>
      <label>{label}</label>
      <div>
        <input type={type} {...otherProps} />
      </div>
      <small style={{ color: "red", visibility: "hidden" }}>
        {`Invalid ${label}`}
      </small>
    </div>
  );
export default ItemForm;
